import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import kalenisLogo from './isotipo-kalenis-ilust.svg'
import KalenisSpinner from './common/KalenisSpinner.js'
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import {

  withRouter,

} from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    // padding:10,
    backgroundColor: 'white',
    width: 50,
    height: 50
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  forgotPassword: {
    marginTop: theme.spacing.unit * 3,
    textAlign: 'center',

  },
  forgotPasswordText: {

    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      // color: 'rgb(47,136,222)',
      color: "#203b60",
    },

  },
  button: {
    width: '100%'
  }
});

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem("email") || "",
      password: localStorage.getItem("password") || "",
      loading: false,
      forgotDialog: false,
      infoDialog: false,
      infoMessage: { title: "", msg: "" },
      fields_schema: Yup.object().shape({
        email: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        password: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
          
      }),
    }

    this.login = this.login.bind(this)
    this.getToken = this.getToken.bind(this);
    this.handleTextField = this.handleTextField.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.handleForgotDialog = this.handleForgotDialog.bind(this);
    this.handleInfoDialog = this.handleInfoDialog.bind(this);
    this.requestPasswordReset = this.requestPasswordReset.bind(this);
    this.getPasswordResetEmail = this.getPasswordResetEmail.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    // this.registerUser = this.registerUser.bind(this);

  }




  handleTextField(event) {

    const name = event.target.name;
    this.setState({
      [name]: event.target.value
    });

  }

  handleForgotDialog() {
    this.setState({
      forgotDialog: !this.state.forgotDialog
    })
  }

  handleInfoDialog(infoMessage) {
    var msg;
    if (infoMessage) {
      msg = infoMessage
    }
    else {
      msg = { title: "", msg: "" }
    }

    this.setState({
      infoDialog: !this.state.infoDialog,
      infoMessage: msg
    })
  }

  requestPasswordReset() {
    console.log("Requesting Password")
    this.handleLoading()
    this.getPasswordResetEmail()
    this.handleForgotDialog()
  }

  getPasswordResetEmail() {
    var body_req = JSON.stringify({
      email: this.state.email,
      // company:"Kalenis LLC",
      // password: this.state.password

    })

    return fetch("/api/kalenis_web/user/reset_password", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: body_req

    })
      .then((response) => {


        return response.json();
      }).then((json) => {

        if (json.status === false) {
          alert("Verifique los datos ingresados")
          return false
        }


      }).then(() => {
        this.handleLoading()
        this.handleInfoDialog({ title: "Recuperación Exitosa", msg: "Le hemos enviado un correo electrónico con las instrucciones para recuperar su contraseña" })
      })
  }


  getToken(values) {
    var body_req = JSON.stringify({
      email:values.email,
      // company:"Kalenis LLC",
      password: values.password

    })

    return fetch("/api/kalenis_web/user/get_token", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: body_req

    })
      .then((response) => {

        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }

      }).then((json) => {

        if (json) {
          this.props.login(json)

          localStorage.setItem("email", this.state.email)
          localStorage.setItem("password", this.state.password)
          this.props.history.push('/support/list')

        }
        else {
          console.log("Respnse disinta de 200")
          this.handleLoading()
          this.handleInfoDialog({ title: "Verifique los datos ingresados", msg: "El usuario y/o la contraseña son incorrectos, por favor inténtelo nuevamente" })
        }

        console.log("pase")

      })
  }


  handleLoading() {
    this.setState({
      loading: !this.state.loading
    })
  }


  login(values) {

    this.handleLoading()
    this.getToken(values)




  }

  handleEnter(e) {
    // console.log("Handling enter")

    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.login();

    }

  }

  render() {
    //bugpoint
    // const { classes, ...other } = this.props;
    const { classes, t } = this.props
    return (
      <main  className={classes.main}>

        <CssBaseline />


        <Paper className={classes.paper}>
          {/* <Avatar src={kalenisLogo} className={classes.avatar}> */}
          {/* <LockOutlinedIcon /> */}

          <img style={{ height: 50, width: 50 }} src={kalenisLogo} alt="Kalenis"></img>
          {/* </Avatar> */}
          <Typography component="h1" variant="h5">
            Kalenis
        </Typography>

          <Formik
            initialValues={{
              email:localStorage.getItem("email") || "",
              password:localStorage.getItem("password") || ""
              // email:"",
              // password:""
          
            }}
           
            
            validationSchema={this.state.fields_schema}
            onSubmit={values => {
              // same shape as initial values
              this.login(values)
              console.log(values)
            }}
          >
            {({ errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              values }) => (
                <form className={classes.form} 
                onKeyDown={(e)=>{
                  
                  if (e.key === 'Enter') {
                   
                    e.preventDefault()
                    e.stopPropagation()
                    handleSubmit()
                 }}}
                      onSubmit={handleSubmit} 
                      noValidate>

                  <Grid container spacing={0}>
                    <KalenisSpinner show={this.state.loading} fullScreen={true} size={50} />
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">{t('email')}</InputLabel>
                        <Input error={errors.email && touched.email ? true:false} id="email" name="email" value={values.email}  onChange={handleChange}  autoFocus /> 
                        <FormHelperText error={errors.email && touched.email ? true:false}>{errors.email && touched.email ? errors.password:""}</FormHelperText>
                        {/* autoComplete="email" */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">{t('password')}</InputLabel>
                        <Input error={errors.password && touched.password ? true:false} name="password" value={values.password} onChange={handleChange} type="password"  id="password"  />
                        <FormHelperText error={errors.password &&  touched.password ? true:false}>{errors.password &&  touched.password ? errors.password:""}</FormHelperText>
                        
                      </FormControl>
                    </Grid>


                    {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                    <Grid item xs={12}>
                    
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        // onClick={this.login}
                        className={classes.submit}
                      >
                        {t('login')}
                   </Button>

                    </Grid>

                    <Grid className={classes.forgotPassword} item xs={12}>
                      <Typography onClick={this.handleForgotDialog} className={classes.forgotPasswordText} variant="subtitle2">{t('forgot_password')}</Typography>
                    </Grid>

                  </Grid>



                </form>
              )}
          </Formik>
          <Dialog
            // fullScreen={fullScreen}
            open={this.state.infoDialog}
            onClose={this.handleInfoDialog}
            maxWidth='sm'
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="info-dialog-title">{this.state.infoMessage.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="info-dialog-description">
                {this.state.infoMessage.msg}
              </DialogContentText>

            </DialogContent>
            <DialogActions>
              <Grid justify={'flex-end'} container spacing={8}>
                <Grid item xs={12} sm={4}>
                  <Button onClick={this.handleInfoDialog} className={classes.button} variant="contained" color="primary" autoFocus>
                    Aceptar
                      </Button>
                </Grid>

              </Grid>

            </DialogActions>
          </Dialog>
          <Dialog
            // fullScreen={fullScreen}
            open={this.state.forgotDialog}
            onClose={this.handleForgotDialog}
            maxWidth='sm'
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{t('password_reset_title')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('password_reset_body')}
          </DialogContentText>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{t('email')}</InputLabel>
                <Input id="email" name="email" value={this.state.email} onChange={this.handleTextField} autoComplete="email" autoFocus />
              </FormControl>

            </DialogContent>
            <DialogActions>
              <Grid justify={'flex-end'} container spacing={8}>

                <Grid item xs={12} sm={4}>
                  <Button className={classes.button} onClick={this.handleForgotDialog} variant="contained" >
                    {t('cancel')}
                       </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button onClick={this.requestPasswordReset} className={classes.button} variant="contained" color="primary" autoFocus>
                    {t('submit')}
                      </Button>
                </Grid>

              </Grid>

            </DialogActions>
          </Dialog>
        </Paper>
      </main>
    );
  }
}


Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withTranslation()(Login)));
