import React, { Component } from 'react';
import '../App.css';

// import kalenis_logo from '../img/isotipo.png'
import CircularProgress from '@material-ui/core/CircularProgress';

class KalenisSpinner extends Component {

    constructor(props) {
        super(props);
        this.state = {

            show_alert: {
                open: false,
                message: "",
                close: this.closeAlert
            }


        }
        this.onClickLoader = this.onClickLoader.bind(this);
    }
    showAlert(message) {
        this.setState({
            show_alert: {
                open: true,
                message: message,
                close: this.closeAlert,
            }
        }

        )
    }

    closeAlert() {
        this.setState({
            show_alert: {
                open: false,
                message: "",
                close: this.closeAlert
            }
        })
    }
    onClickLoader(){
        alert("Aguarde un minuto por favor");
    }

    render() {

        return (
            <React.Fragment>
                {this.props.show === true ? (
                    <div className={this.props.fullScreen ? "kalenis-loading":"kalenis-loading-relative"} onClick={this.onClickLoader}>
                        {/* <img src={kalenis_logo} className="kalenis-spinner" alt="logo" /> */}
                        {this.props.message ? <div className="kalenis-spinner">{this.props.message}</div>:<CircularProgress className="kalenis-spinner" size={this.props.size ? this.props.size:70} />}
                        
                    </div>
                ) : null}

            </React.Fragment>
        );
    }
}


export default KalenisSpinner;