import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import SearchBar from '../common/SearchBar.js'
import AssistanceListItem from './AssistanceListItem.js'
import { Flipped } from "react-flip-toolkit";
import { withTranslation } from 'react-i18next';

import {
  
  
  withRouter,

} from 'react-router-dom'



const orderOptions = (t) => [
  { 'label': 'ID', 'value': 'id', 'type':'number' },
  { 'label': t('date'), 'value': 'date', 'type':'date' },
  { 'label': t('activity'), 'value': 'unread_notes', 'type':'number', 'default':true },
  { 'label': t('status'), 'value': 'state', 'type':'object' },
  { 'label': t('category'), 'value': 'category', 'type':'object' }
]

const smartFilters = (t) => [
  {label:t('hide_done_filter'), value:'hide_done', type:'icon', icon:'doneAll', query:{state:['open', 'pending']} }
]


const AssistanceListItemFlipped = ({ knownProp, ...rest }) => (<AssistanceListItem {...rest}/>)
const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const states = (t) => [
  { 'value': 'open', 'label': t('open') },
  { 'value': 'pending', 'label': t('feedback') },
  { 'value': 'done', 'label': t('finished') }
]

// const priorities = [
//   { 'value': "4", 'label': '4- Baja' },
//   { 'value': "3", 'label': '3- Normal' },
//   { 'value': "2", 'label': '2- Alta' },
//   { 'value': "1", 'label': '1- Crítico' },

// ]

const kinds = (t) => [
  { 'value': 'bug', 'label': t('error') },
  { 'value': 'question', 'label': t('question') }
]



class AssistanceListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      categoryOptions:[],
      

    }
    this.orderBy = this.orderBy.bind(this)
    this.search = this.search.bind(this);
    this.getSearchOptions = this.getSearchOptions.bind(this)


  }

  componentDidMount() {

    console.log("list view")
    this.getCategories()
  }



  orderBy(key) {
    console.log("order by en list view")
    this.props.orderBy(key)
  }


  search(value, orderBy){
    this.props.search(value, orderBy)
  }

  getCategories() {
    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)
    return fetch("/api/kalenis_web/support/category"+company, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      // body: body_req

    })
      .then((response) => {
        if (response.status !== 200) {
          if(response.status === 401){
            this.props.logout()
            this.props.history.push({pathname:'login'})
            return false
          }
          return false
        }
        else {
          return response.json();
        }


      }).then((json) => {

        if (json) {
          console.log("response Categories")
          console.log(json);

          var options = json.map(function(option){
            return {'label':option.name, 'value':option.id }
          })
          this.setState({
            categoryOptions: options,

          })
        }
        else {
          // this.handleInfoDialog({ title: "Ha ocurrido un error", msg: "Por Favor inténtelo mas tarde" })
          return false;
        }


      })
  }

  getSearchOptions(){
    const t = this.props.t
    var assigned_options = [{ 'value': 0, 'label': 'Kalenis' }];
    if(this.props.selectedCompany != 0){
      var selectedCompany = [...this.props.session.user.parties].filter(function(party){return party.id === this.props.selectedCompany}.bind(this))
      assigned_options.push({'value':selectedCompany[0].id, 'label':selectedCompany[0].name})
      
    }
    else{
      this.props.session.user.parties.map(function(party){
        assigned_options.push({'value':party.id, 'label':party.name})
      })
    }
    
    
    return [
      {label:'ID', value:'id', type:'char', main_code:true },
      {label:t('title'), value:'title', type:'char', main:true },
      {label:t('description'), value:'description', type:'char', expand:true },
      {label:t('start_date'), value:'start_date', type:'date' },
      {label:t('end_date'), value:'end_date', type:'date' },
      {label:t('status'), value:'state', type:states(t).length > 2? 'multi_select':'select', options:states(t) },
      {label:t('kind'), value:'kind', type:kinds(t).length>2?'multi_select':'select', options:kinds(t)},
      {label:t('category'), value:'category', type:this.state.categoryOptions.length > 2 ? 'multi_select':'select', options:this.state.categoryOptions },
      {label:t('assigned_to'), value:'assigned_to', type:'select', options:assigned_options}

    ]
  }





  render() {
    const { t } = this.props;
    return (
      <div>
     
        <SearchBar data={this.props.issues}
          selected_order={this.props.selected_order}
          orderBy={this.orderBy}
          reverseOrder={this.props.reverseOrder}
          search={this.search}
          searchOptions={this.getSearchOptions()}
          smartFilters={smartFilters(t)}
          orderOptions={orderOptions(t)} />

    
        {this.props.issues.map((issue) => (
          <Flipped key={issue.id} flipId={issue.id}>

            
            <div key={issue.id}><AssistanceListItemFlipped item={issue} session={this.props.session} /></div>
            {/* <div key={issue.id}>{issue.id}</div> */}

          {/* {(flippedProps, issue) => <AssistanceListItemFlipped flippedProps={flippedProps} item={issue}
              key = {issue.id} />} */}
            
              </Flipped>
         
        ))}
         
        </div>
     

    );
  }
}


AssistanceListView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withTranslation()(AssistanceListView)));
