import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Hidden from '@material-ui/core/Hidden';


import RefreshIcon from '@material-ui/icons/Refresh';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';

import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import { withTranslation } from 'react-i18next';

import _ from 'lodash';
// import Select from '@material-ui/core/Select';




const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    marginBottom: theme.spacing.unit * 3,
    overflow: 'hidden',
    // padding:theme.spacing.unit,
    // position:'sticky'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    maxWidth: 936,

    margin: 'auto',
    marginBottom: theme.spacing.unit * 1,
    marginTop: theme.spacing.unit,
    overflow: 'hidden',
    padding: theme.spacing.unit,
    top: theme.spacing.unit * 6,
    left: 'auto',
    right: 0,
    position: 'sticky'
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
    // color:'rgba(0, 0, 0, 0.87)'
  },
  block: {
    display: 'block',
  },
  orderDirection: {
    display: 'block',
    cursor: 'pointer'
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  formControl: {
    // margin: theme.spacing.unit,
    marginBottom: "20px",
    minWidth: 120,
  },
  searchField: {
    // padding: '2px 4px',
    display: 'flex',
    // alignItems: 'center',
    // width: 400,
  },
  dividerSearch: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchPopover: {
    maxWidth: 936,
    overflow: 'hidden',
    // minWidth:500,
    borderRadius: '0px',
    outline: 'none',
    position: 'absolute',
    minWidth: '16px',
    // maxWidth: 'calc(100% - 32px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: '16px',
    maxHeight: 'calc(100% - 32px)',

  },
  searchPopoverGrid: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3,

    //  flexGrow:1
  },
  textFieldLabel: {
    // color: "red",
    textAlign: "center",
    fontFamily: [
      'Open Sans',
      'sans-serif'

    ].join(','),
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    // // width: 50,

    color: "#203b60",
    textAlign: "left",
    fontWeight: '500',

    fontFamily: [
      'Open Sans',
      'sans-serif'

    ].join(','),
  },
  button: { width: '100%' },
  buttonGrid: { marginTop: theme.spacing.unit * 2 },
  chip: {
    // margin: theme.spacing.unit,
  },
  chipContainer: {
    maxWidth: 936,
    margin: 'auto',
    marginBottom: theme.spacing.unit * 1,
    overflow: 'hidden',
    // padding:theme.spacing.unit,

  },
  tooltip: {
    backgroundColor: theme.palette.grey[300],
    color: 'black',
    opacity: 1,
    border: '1px solid #dadde9',
  }
});




class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orderBy: 0,
      orderDir: true,
      search_input: "",
      search: {},
      smart_filters: {},
      draft_search: {},
      show_alert: {
        open: false,
        message: "",
        close: this.closeAlert,
        searchPanel: false,

      }
    }
    this.orderBy = this.orderBy.bind(this);
    this.onClickLoader = this.onClickLoader.bind(this);
    this.changeOrderDirection = this.changeOrderDirection.bind(this)
    this.search = this.search.bind(this);
    this.setFullSearch = this.setFullSearch.bind(this);
    this.openSearchPanel = this.openSearchPanel.bind(this);
    this.closeSearchPanel = this.closeSearchPanel.bind(this);
    this.setFilters = this.setFilters.bind(this)
    this.handleEnter = this.handleEnter.bind(this);
    this.setDraftFilters = this.setDraftFilters.bind(this);
    this.refresh = this.refresh.bind(this);
    this.onChangeOrder = this.onChangeOrder.bind(this);
    this.getOrderType = this.getOrderType.bind(this);
    this.searchInputChange = this.searchInputChange.bind(this);
  }

  componentDidMount() {
    console.log("Search Bar")
    var search = {}
    var draft_search = {}
    this.props.searchOptions.map(function (option) {// eslint-disable-line
      search[option.value] = { label: option.label }

      draft_search[option.value] = ""

      if (option.type === 'multi_select') {

        //Support multi select: Changed default draft search to array
        // draft_search[option.value] = { value: "", options: option.options }
        draft_search[option.value] = { value: [], options: option.options }


        // search[option.value] = { label: option.label, options:option.options }
        // search[option.value] = { label: option.label, value:"" }
      }
      if (option.type === 'select') {

        //Support multi select: Changed default draft search to array
        draft_search[option.value] = { value: "", options: option.options }
        // draft_search[option.value] = { value: [], options: option.options }


        // search[option.value] = { label: option.label, options:option.options }
        // search[option.value] = { label: option.label, value:"" }
      }
    })



    // this.orderBy({target:{value:this.props.orderOptions.filter(o=>{return o.default === true})[0].value}})

    this.setState({ search: search, draft_search: draft_search })

  }



  openSearchPanel(e) {


    this.setState({
      searchPanel: e.currentTarget
    })
  }

  searchInputChange(e) {
    this.setState({
      search_input: e.target.value
    })
  }


  closeSearchPanel(e) {


    this.setState({
      searchPanel: null
    })
  }

  setFilters() {
    var search = { ...this.state.search }
    // console.log("Draft search on setFilters")
    // console.log(this.state.draft_search)
    Object.keys(this.state.draft_search).map(function (filter) {

      // if (typeof this.state.draft_search[filter] === 'object' && this.state.draft_search[filter].value === "") {
      if (typeof this.state.draft_search[filter] === 'object' && _.isEmpty(this.state.draft_search[filter].value)) {
        return false
      }

      if (typeof this.state.draft_search[filter] === 'object') {
        search[filter].value = this.state.draft_search[filter].value
      }

      else if (this.state.draft_search[filter] !== "") {
        search[filter].value = this.state.draft_search[filter]
      }

    }.bind(this))

    console.log("Filters to SET")
    console.log(search)

    this.resetDraftFilters()
    this.setState({
      search: search
    }, () => { this.search() })

  }

  setDraftFilters(e) {
    // console.log("Ejecuto setDraft Filters")
    // console.log(e.target.name)
    // console.log(e.target.value)
    // console.log("Search State")
    // console.log(this.state.search)

    if (this.state.search[e.target.name].value) {
      this.removeSearchArg("", e.target.name, false)
    }


    var draft_search = { ...this.state.draft_search }

    //added to multi selects

    // if (typeof draft_search[e.target.name] === 'object') {

    //   if (_.isArray(e.target.value)) {

    //     console.log("Es un array=>Valor")
    //     console.log(e.target.value)
    //     draft_search[e.target.name].value = e.target.value

    //   }


    //   // draft_search[e.target.name].value = e.target.value


    // }
    // else {
    // draft_search[e.target.name] = e.target.value

    if (typeof draft_search[e.target.name] === 'object' && _.isArray(e.target.value)) {
      draft_search[e.target.name].value = e.target.value
    }
    else {
      draft_search[e.target.name] = e.target.value
    }

    // }




    console.log("Draft Search on Set")
    console.log(draft_search)
    this.setState({
      draft_search: draft_search
    })
  }

  resetDraftFilters(e) {
    var draft_search = {}
    this.props.searchOptions.map(function (option) {// eslint-disable-line
      // search[option.value] = {label:option.label}
      draft_search[option.value] = ""
    })
    this.setState({ draft_search: draft_search })
  }

  onClickLoader() {
    alert("Loading..");
  }

  onChangeOrder(e) {

    this.orderBy(e.target.value)
  }

  getOrderType(value) {

    return this.props.orderOptions.filter(o => { return o.value === value })[0].type
  }

  orderBy(value) {
    console.log("searchBar order by")
    console.log(this.props.orderOptions.filter(o => { return o.value === value })[0].type)
    this.props.orderBy({ value: value, type: this.getOrderType(value) })
  }

  changeOrderDirection() {
    this.setState({
      orderDir: !this.state.orderDir
    })
    this.props.reverseOrder()
  }

  search(e, no_panel) {
    console.log("ORder dir on search bar search")
    console.log(this.state.orderDir)
    var orderBy = this.props.selected_order ? { value: this.props.selected_order, reverse: this.state.orderDir, type: this.getOrderType(this.props.selected_order) } : false
    if (no_panel !== true) {
      this.closeSearchPanel()
    }


    this.props.search(this.state.search, orderBy)
  }

  refresh() {

    this.search("", true)

  }


  setFullSearch(e) {
    console.log("simple search")

    var field;
    var value = this.state.search_input;
    var search = { ...this.state.search }



    if (value.startsWith('#')) {
      field = this.props.searchOptions.filter((o) => { return o.main_code === true })[0].value
      value = parseInt(value.substr(1))

      if (_.isNaN(value)) {
        this.setState({
          search_input: ""
        })
        return false
      }


    }

    else {
      field = this.props.searchOptions.filter((o) => { return o.main === true })[0].value
    }

    console.log(field)
    console.log(value)

    search[field].value = value


    this.setState({
      search: search,
      search_input: ""
    }, () => { this.search() })


    // this.setState({
    //   fullSearch: e.target.value
    // })
  }

  getField(field, classes) {
    switch (field.type) {
      case 'char': return (
        <Grid key={field.value} item xs={field.expand ? 12 : 6} style={{ padding: "4px" }}>
          <FormControl margin="normal" required fullWidth>
            <TextField


              id={field.value}
              name={field.value}
              label={field.label}
              fullWidth
              value={this.state.search[field.value] ? this.state.search[field.value]['value'] : this.state.draft_search[field.value]}
              onChange={this.setDraftFilters}
              InputProps={{

                classes: {
                  root: classes.textField,

                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </FormControl>
        </Grid>
      )
      case 'date': return (<React.Fragment key={field.value}>
        <Grid  item xs={field.expand ? 12 : 6} style={{ padding: "4px" }}>
          <FormControl margin="normal" required fullWidth>
            <TextField

              id={field.value}
              name={field.value}
              label={field.label}
              fullWidth
              type="date"
              value={this.state.search[field.value] ? this.state.search[field.value]['value'] : this.state.draft_search[field.value]}
              onChange={this.setDraftFilters}
              InputProps={{

                classes: {
                  root: classes.textField,

                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </FormControl>
        </Grid>

      </React.Fragment>
      )
      case 'multi_select': return (
        <Grid key={field.value} item xs={field.expand ? 12 : 6} style={{ padding: "4px" }}>
          <FormControl margin="normal" fullWidth>
            <InputLabel className={classes.textFieldLabel} shrink={true} htmlFor={field.value}>{field.label}</InputLabel>
            <Select
              id={field.value}
              name={field.value}
              label={field.label}
              fullWidth

              multiple
              onChange={this.setDraftFilters}
              value={(typeof this.state.search[field.value] === 'object' && this.state.search[field.value].hasOwnProperty('value')) ? this.state.search[field.value].value : typeof this.state.search[field.value] === 'object' && this.state.draft_search[field.value].hasOwnProperty('value') && _.isArray(this.state.draft_search[field.value]['value']) ? this.state.draft_search[field.value]['value'] : []}
              // value={ _.isArray(this.state.draft_search[field.value]) ? this.state.draft_search[field.value]: [] }
              inputProps={{

                classes: {
                  root: classes.textField,
                },
              }}
            // InputLabelProps={{
            //   classes: {
            //     root: classes.textFieldLabel,
            //   },
            // }}

            >
              {field.options.map(option => (
                <MenuItem className="select-field-item" key={"state_" + option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>)

      case 'select': return (
        <Grid key={field.value} item xs={field.expand ? 12 : 6} style={{ padding: "4px" }}>
          <FormControl margin="normal" fullWidth>
            <TextField

              id={field.value}
              name={field.value}
              label={field.label}
              fullWidth
              select
              multiple
              onChange={this.setDraftFilters}
              value={(typeof this.state.search[field.value] === 'object' && this.state.search[field.value].hasOwnProperty('value')) ? this.state.search[field.value]['value'] : this.state.draft_search[field.value]}
              InputProps={{

                classes: {
                  root: classes.textField,
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.textFieldLabel,
                },
              }}

            >
              {field.options.map(option => (
                <MenuItem className="select-field-item" key={"state_" + option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

            </TextField>
          </FormControl>
        </Grid>)
      default: return "Filtro no soportado"
    }
  }



  smartFilterClick(e, filter) {
    console.log("Smart Filter Click")
    console.log(filter)
    let search = { ...this.state.search }
    let smart_filters = { ...this.state.smart_filters }

    if (!smart_filters[filter.value]) {

      Object.keys(filter.query).map(function (field) {// eslint-disable-line

        search[field].value = filter.query[field]
        search[field].smart = true;
        // if (_.isArray(filter.query[field])) {
        //   var string_query = ""
        //   filter.query[field].map(function (value) {
        //     string_query = string_query + value + ';'
        //     search[field].value = string_query
        //     search[field].smart = true;
        //   })

        // }


      })
      console.log("Search in smar filter")
      console.log(search)
      smart_filters[filter.value] = true

    }
    else {
      //Remove Filter
      Object.keys(filter.query).map(function (field) {
        if (_.isArray(filter.query[field])) {

          this.removeSearchArg("", field, false)

        }

      }.bind(this))
      smart_filters[filter.value] = false


    }


    this.setState({
      search: search,
      smart_filters: smart_filters
    }, () => { this.search() })


  }

  getIcon(name, props, classes) {

    const icons = {
      'doneAll': <IconButton className={classes.block}> <DoneAllIcon {...props} /> </IconButton>
    }

    return icons[name]
  }

  getSmartFilter(filter, classes) {
    switch (filter.type) {
      case 'icon': return (
        <Grid item xs={12} key={filter.value}>
          <Tooltip title={filter.label} classes={{ tooltip: classes.tooltip }}>
            {this.getIcon(filter.icon, {
              color: this.state.smart_filters[filter.value] ? "inherit" : 'primary',
              onClick: function (e) { this.smartFilterClick(e, filter) }.bind(this)
            }, classes)}
          </Tooltip>
        </Grid>)

      case 'checkbox': return (
        <Grid item xs={12} key={filter.value}>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                // onChange={this.handleChange('checkedG')}
                // value="checkedG"
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
              />
            }
            label="Ocultar Resueltos"
          />
        </Grid>)
      default: return "Smart filter no definido"

    }
  }

  handleEnter(e) {
    // console.log("Handling enter")

    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      console.log("soy un enter")
      this.setFilters()

    }
  }

  removeSearchArg(e, key, _search) {
    console.log("Removing Search arg")
    console.log(key)
    let search = { ...this.state.search }
    // search[key].value = undefined
    delete search[key].value
    console.log("Deleted from search")
    console.log(search)
    this.setState({ search: search }, () => { if (_search) { this.search() } })
  }

  getChipLabel(key) {
    var label = ""
    // console.log("Getting Chip label")
    // console.log(this.props.searchOptions.filter((o) => { return o.value === key })[0].type)
    var item = this.props.searchOptions.filter((o) => { return o.value === key })[0]

    console.log("ITEM on chip label")
    console.log(item)
    switch (item.type) {
      case 'select':
        label = this.state.search[key].label + "=" + item.options.filter((o) => { return o.value === this.state.search[key].value })[0].label
        break;
      case 'multi_select':
        var string_values = ""

        this.state.search[key].value.map(function (value) {// eslint-disable-line
          string_values = string_values.concat(item.options.filter((o) => { return o.value === value })[0].label).concat(',')
        })
        label = this.state.search[key].label + "=" + string_values
        break;
      default:
        label = this.state.search[key].label + "=" + this.state.search[key].value
        break;
    }

    return label

    // if (item.type === 'select') {
    //   if (item.multi_select) {

    //     var string_values = ""
    //     console.log("Item on get chip label")
    //     console.log(item)
    //     var translated_value = this.state.search[key].value.map(function(value){
    //       string_values = string_values.concat(item.options.filter((o) => { return o.value === value })[0].label).concat(',')
    //     //  return item.options.filter((o) => { return o.value === value })[0].label

    //     })

    //     label = this.state.search[key].label + "=" + string_values
    //     // label = this.state.search[key].label + "=" + item.options.filter((o) => { return o.value === this.state.search[key].value })[0].label
    //   }
    //   else {
    //     // console.log(this.state.search[key].value)
    //     label = this.state.search[key].label + "=" + item.options.filter((o) => { return o.value === this.state.search[key].value })[0].label
    //   }


    // }
    // else {
    //   label = this.state.search[key].label + "=" + this.state.search[key].value

    // }
    // return label

  }

  render() {
    const { classes, t } = this.props;

    return (
      // <Paper className={classes.paper}>

      <React.Fragment>
        <AppBar className={classes.searchBar} color="inherit" elevation={1}>
          <Toolbar>
            <Grid container spacing={16} alignItems="center">
              <Grid item xs={6} sm={6} className={classes.searchField} >
                <Tooltip
                  classes={{

                    tooltip: classes.tooltip,
                  }}
                  title={t("advanced_filters")}
                // <React.Fragment>
                //   <Typography  color="textPrimary">Filtros Avanzados</Typography>
                // </React.Fragment>}
                >

                  <IconButton onClick={this.openSearchPanel} className={classes.iconButton} aria-label="Menu">
                    <ExpandMoreIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  classes={{

                    tooltip: classes.tooltip,
                  }}
                  title={
                    <React.Fragment>
                      <Typography variant="caption" color="textPrimary">{t('id_filter_tooltip')}</Typography>
                      {/* <Typography variant="caption" color="textPrimary">{t} </Typography> */}
                      <Typography variant="caption" color="textPrimary">{t('advanced_filters_tooltip')}</Typography>
                    </React.Fragment>}>
                  <InputBase
                    // disableUnderline

                    className={classes.searchInput}
                    // onBlur={this.setFullSearch}
                    onKeyDown={(e) => {

                      if (e.key === 'Enter') {

                        e.preventDefault()
                        e.stopPropagation()
                        this.setFullSearch()
                      }
                    }}
                    onChange={this.searchInputChange}
                    value={this.state.search_input}
                    // InputProps={{
                    //   // disableUnderline: true,

                    //   className: classes.searchInput,
                    // }} 
                    placeholder={t("searcher")} />
                </Tooltip>
                <IconButton className={classes.IconButton} onClick={this.setFullSearch} aria-label="Search">
                  <SearchIcon />
                </IconButton>


              </Grid>

              {/* <Grid  item style={{ flexBasis: 0 }} > */}
                <Grid xs={6} sm={6}  container spacing={0} justify="flex-end" alignItems="center" direction="row" >
                  <Grid item sm={2}>
                    {this.props.smartFilters.map((filter) => (
                      this.getSmartFilter(filter, classes)
                    ))}
                  </Grid>
                  <Hidden smDown>

                    <Grid item style={{ flexBasis: 0 }} xs={10} sm={10}>
                      <form className={classes.root} autoComplete="off">
                        <FormControl className={classes.formControl}>
                    <InputLabel className={classes.searchInput} htmlFor="order-by">{t('sort')}</InputLabel>
                          <Select
                            value={this.props.selected_order}
                            onChange={this.onChangeOrder}

                            // input={<OrderInput name="orderBy" id="order-by" />}
                            inputProps={{
                              name: 'orderBy',
                              id: 'order-by',
                              // disableUnderline: true,
                              className: classes.searchInput
                            }}
                          >


                            {this.props.orderOptions.map((option) => (
                              <MenuItem key={option.value} className="select-field-item" value={option.value}>{option.label}</MenuItem>
                            ))}

                          </Select>

                        </FormControl>
                      </form>
                    </Grid>
                    <Grid item xs={2} style={{ flexBasis: 0 }}>
                      {this.state.orderDir === true &&
                        <Tooltip
                          classes={{

                            tooltip: classes.tooltip,
                          }}
                          title={t("switch")}
                        >
                          <IconButton onClick={this.changeOrderDirection} className={classes.block} aria-label="downward">
                            <ArrowDownward className={classes.orderDirection} color="inherit" />
                          </IconButton>
                        </Tooltip>
                      }

                      {this.state.orderDir === false &&
                        <Tooltip
                          classes={{

                            tooltip: classes.tooltip,
                          }}
                          title={"Invertir Orden"}

                        >
                          <IconButton onClick={this.changeOrderDirection} className={classes.block} aria-label="upward">
                            <ArrowUpward className={classes.orderDirection} color="inherit" />
                          </IconButton>
                        </Tooltip>
                      }

                    </Grid>

                  </Hidden>
                  <Grid item xs={2} style={{ flexBasis: 0 }}>
                    <Tooltip
                      classes={{

                        tooltip: classes.tooltip,
                      }}
                      title={t('refresh')}

                    >
                      <IconButton onClick={this.refresh} className={classes.block} aria-label="refresh">
                        <RefreshIcon color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                </Grid>

              {/* </Grid> */}

              {/* <Grid item xs={1}>
              {this.state.orderDir === true &&
                <IconButton onClick={this.changeOrderDirection} className={classes.button} aria-label="downward">
                  <ArrowDownward className={classes.orderDirection} color="inherit" />
                </IconButton>
              }

              {this.state.orderDir === false &&
                <IconButton onClick={this.changeOrderDirection} className={classes.button} aria-label="upward">
                  <ArrowUpward className={classes.orderDirection} color="inherit" />
                </IconButton>
              }




            </Grid> */}




              {/* <Grid item>
              <Button variant="contained" color="primary" className={classes.addUser}>
                Add user
                          </Button>
              <Tooltip title="Reload">
                <IconButton>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip>
            </Grid> */}
            </Grid>
          </Toolbar>


          <Popover onKeyDown={this.handleEnter} open={Boolean(this.state.searchPanel)}
            anchorEl={this.state.searchPanel}
            className={classes.searchPopover}

            PaperProps={
              {
                square: true,
                //  className:classes.searchPopover
              }
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={this.closeSearchPanel}
            timeout="auto"
          >

            <Grid className={classes.searchPopoverGrid} container spacing={0} alignItems="flex-start">
              {this.props.searchOptions.map((field) => (
                this.getField(field, classes)
              ))}

              <Grid item xs={12} >

                <Grid className={classes.buttonGrid} container alignItems="flex-end" justify="flex-end" spacing={16}>
                  <Grid item xs={4} >
                    <Button type="button" onClick={this.closeSearchPanel} variant="contained" className={classes.button}>
                      {t('cancel')}
                        {/* <DoneIcon className={classes.block} color="inherit" /> */}
                    </Button>
                  </Grid>
                  <Grid item xs={4} >
                    <Button type="button" variant="contained" onClick={this.setFilters} color='primary' className={classes.button}>
                      {t('searcher')}
                        {/* <DoneIcon className={classes.block} color="inherit" /> */}
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Popover>
        </AppBar>
        <div className={classes.chipContainer}>
          {Object.keys(this.state.search).map((filterKey) => (


            <React.Fragment key={filterKey}>
              {(this.state.search[filterKey].value && !this.state.search[filterKey].smart) &&

                <Chip
                  key={filterKey}
                  // icon={<TagFacesIcon />}
                  // label={this.state.search[filterKey].label + "=" + this.state.search[filterKey].value}
                  label={this.getChipLabel(filterKey)}
                  // onDelete={(e, filterKey)=>{this.removeSearchArg(e, filterKey)}}
                  onDelete={function (e) { this.removeSearchArg(e, filterKey, true) }.bind(this)}
                  className={classes.chip} />
              }
            </React.Fragment>
          ))}


        </div>

      </React.Fragment>

      // </Paper>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(SearchBar));