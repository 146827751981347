import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import {

  withRouter,

} from 'react-router-dom'
const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
  },
  iconButtonAvatar: {
    padding: 0,

  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  textField: {

    textAlign: "left",
    // fontWeight: '500',
    color: "white",
    // fontSize:'14px',   
    fontSize: theme.typography.fontSize,
    borderColor: "white",
    // fontFamily: [
    //   'Open Sans',
    //   'sans-serif'

    // ].join(','),

  },
});

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      
    }
    // this.getTitle= this.getTitle.bind(this)
    this.setTitle = this.setTitle.bind(this);
    this.companyChange = this.companyChange.bind(this);
  }

  componentDidMount() {
    console.log("PROPS DEL NAVBAR en header")
    console.log(this.props)
    this.setTitle()
  }

  componentDidUpdate(prevProps) {

    if (prevProps.location.pathname !== this.props.location.pathname) {
      console.log("Header Update")
      console.log(this.props.location)
      this.setTitle()

      // if(this.props.routes.length>0){
      //   console.log("Hay routes")
      //   console.log(this.props.routes)
      //   console.log(this.props.location.pathname)
      //   console.log(this.props.routes.filter(o => { return o.path===this.props.location.pathname})[0].title)

      // }



    }

  }

  setTitle() {
    if (this.props.routes.filter(o => { return o.path === this.props.location.pathname })[0]) {
      this.setState({ title: this.props.routes.filter(o => { return o.path === this.props.location.pathname })[0].title })
    }

  }

  // getTitle(){
  //   console.log("get Title routes")
  //   console.log(this.props.location.routes)
  //   var title = "defaulteando";

  //   switch(this.props.location.pathname){
  //     case '/support/list': 
  //         title= "Centro de asistencia"
  //         break;
  //     case '/support/new': 
  //         return "Nueva solicitud de asistencia"
  //     case '/support/detail': 
  //         return "Editar Solicitud"
  //     case '/users': 
  //         var title =  "Gestionar Usuarios"
  //     case '/apps': 
  //         return "Gestionar Aplicaciones"
  //     case '/invoices': 
  //         return "Facturas"
  //     case '/payments': 
  //         return "Medios de Pago"


  //     default: title =  "Dale que va"
  //   }

  //   this.setState({title:title})

  // }

  companyChange(e){
    console.log("Company Change")
    console.log(e.target.value)
    this.props.setCompany(e.target.value)
  }

  render() {
    const { classes, t } = this.props;
    return (
      <React.Fragment>
        <AppBar color="primary" position="sticky" elevation={2}>
          <Toolbar>
            <Grid container alignItems="center" direction="row" spacing={0}>
              <Hidden smUp>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.onDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>

              {/* <Grid item xs="2" sm="4"/> */}

              {this.props.routes &&
                <Grid item xs={9} sm={9} style={{ textAlign: 'center', paddingLeft:'5em' }}>
                  <Typography color="inherit" variant="h6">
                    {this.state.title}

                  </Typography>
                </Grid>
              }

              <Grid item sm={3}>

              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                spacing={8}>
              <Hidden smDown>
                <Grid item xs={2} sm={8}>
                {this.props.session.user.parties.length >1 &&
                  <Select
                    id="company"
                    name="company"
                    label="Empresa"
                    fullWidth
                    displayEmpty
                    onChange={this.companyChange}
                    value={this.props.selectedCompany}
                    className={classes.textField}
                    // helperText="Please select your currency"
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                  >
                    <MenuItem className="select-field-item" key={"company_all"} value={0}>
                      {t('all')}
                    </MenuItem>
                    {this.props.session.user.parties.map(option => (
                      <MenuItem className="select-field-item" key={"company_" + option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}

                  </Select>
                }


                </Grid>
              </Hidden>

              <Grid style={{ textAlign: 'right' }} item xs={2} sm={1} >
                <IconButton onClick={this.props.logout} color="inherit" className={classes.iconButtonAvatar}>
                  <ExitToApp className={classes.avatar} />
                </IconButton>
              </Grid>

              </Grid>
              </Grid>



            </Grid>
          </Toolbar>
        </AppBar>





      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(withTranslation()(Header)));
