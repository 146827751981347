import React, { Component, Suspense } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Paperbase from './Paperbase'

import './App.css';


class App extends Component {
  render() {
    return (
    <Suspense fallback={<CircularProgress className="kalenis-spinner" size={70} />}>
      <div className="App">
       
        <Paperbase/>
        
      </div>
      </Suspense>
    );
  }
}

export default App;
