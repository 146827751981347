import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { fade } from '@material-ui/core/styles/colorManipulator';

import EditSharp from '@material-ui/icons/EditSharp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import Hidden from '@material-ui/core/Hidden';
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';


const kinds = (t) => ({ 'bug': t('error'), 'question': t('question') })



const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    marginBottom: theme.spacing.unit,
    // overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  issueTitle: {
    // color: "red"

    // backgroundColor: 'red',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',


  },
  issueHeader: {

    // padding: theme.spacing.unit,
    // display: 'flex',
    // flexDirection: 'row',
    // add
    // padding:'0px'
    // backgroundColor:'blue'
  },
  issueHeaderLeft: {
    alignSelf: 'flex-start',
    flexGrow: 1
  },
  issueHeaderRight: {
    alignSelf: 'flex-end',
  },
  grow: {
    flexGrow: 1,
  },
  dateHeader: {
    marginRight: theme.spacing.unit * 3,
    color: "rgba(0, 0, 0, 0.54)",
    whiteSpace: 'nowrap',
    flexGrow: 1

  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  issueBody: {
    padding: theme.spacing.unit,

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  badgeMargin: {
    margin: theme.spacing.unit,
  },
  baseToolbar: {
    padding: '0px'
  },
  labeledSpan: { flexDirection: 'row', display: 'flex' },

  doneText: {
    textDecoration: 'line-through',
    color: "rgba(0, 0, 0, 0.35)",
  },
  chip: {
    padding: '0px',
    height: '12px',
    fontSize: '10px',
    alignSelf: 'flex-end',

  }

});







class AssistanceListItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      menu_open: false,
      anchorEl: null,

    }
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.openDetail = this.openDetail.bind(this)
  }



  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  componentDidMount() {
    var selectedCompany = false;
    if (this.props.session.user.parties.length > 1) {
      selectedCompany = [...this.props.session.user.parties].filter(function (party) { return party.id === this.props.item.party }.bind(this))
      console.log("Selected Company on ListItem")
      console.log(selectedCompany[0].name);
    }
    this.setState({
      selectedCompany: selectedCompany
    })

  }

  openDetail() {
    this.props.history.push({
      pathname: 'detail',
      search: '?id=' + this.props.item.id,
      // state: { some: 'state' }
    })
  }



  render() {
    const { classes, t } = this.props;

    return (
      <div>

        <Paper square={true} className={classes.paper}>
          {this.state.selectedCompany &&

            <Grid item xs="12" sm="12" style={{ padding: '2px', paddingBottom:'4px', margin: 'auto' }} justify="flex-start" alignItems='flex-start'>

              <Chip
                key={this.props.item.id}

                label={this.state.selectedCompany[0].name}
                // onDelete={(e, filterKey)=>{this.removeSearchArg(e, filterKey)}}
              
                className={classes.chip} />

            </Grid>

          }

          <AppBar position="static" color="inherit" className={classes.issueHeader}>
            <Toolbar className={classes.baseToolbar}>


              <Grid container alignItems='center' justify="space-between" spacing={0}>


                <Grid item  >
                  <IconButton
                    aria-owns={this.state.menu_open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.openDetail}
                    color={this.props.item.state.id === "done" ? 'disabled' : 'primary'}
                  >
                    <EditSharp />
                  </IconButton>
                </Grid>


                <Grid item xs={4}  >
                  {/* <span className={classes.issueTitle}> */}
                  <Typography className={this.props.item.state.id === 'done' ? classes.doneText : ""} color={"inherit"} >
                    [ {this.props.item.id} ] {this.props.item.title}
                  </Typography>
                  {/* </span> */}
                </Grid>




                {/* <div className={classes.grow} /> */}
                <Hidden only="xs">

                  <Grid item xs={2} >
                    <Typography className={this.props.item.state.id === 'done' ? classes.doneText : classes.dateHeader}  >
                      {this.props.item.category.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Typography className={this.props.item.state.id === 'done' ? classes.doneText : classes.dateHeader} >
                      {/* [ {moment(this.props.item.date).format('DD-MM-YYYY')} ] */}

                      [ {t('empty_date',{date:moment(this.props.item.date)})} ]
              </Typography>
                  </Grid>

                </Hidden>


                <Grid item xs={1} >
                  <Typography className={this.props.item.state.id === 'done' ? classes.doneText : ""} color="inherit" >
                    {/* {status[this.props.item.state]} */}
                    {this.props.item.state.label}
                  </Typography>
                </Grid>

                <Grid item xs={1} >
                  <IconButton aria-label="4 pending messages" className={classes.badgeMargin}>
                    <Badge badgeContent={this.props.item.unread_notes} color="primary" >
                      <NotificationsIcon color={this.props.item.state.id === "done" ? 'disabled' : this.props.item.unread_notes > 0 ? 'primary' : 'disabled'} />
                    </Badge>
                  </IconButton>
                </Grid>
                <Grid item xs={1} >
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded,
                    })}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                    aria-label="Show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>


          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>

            <Grid className={classes.issueBody} container alignItems="left" justify="space-between" >

              <Grid item xs="6" sm="3">
                <span className={classes.labeledSpan}>
                  <Typography color="textPrimary" variant="body2" style={{ marginRight: '3px' }} >

                   { t('priority')}
                </Typography>

                  <Typography color="textSecondary" variant="body2" >

                    {/* {priorities[this.props.item.priority]} */}
                    {this.props.item.priority.label}
                  </Typography>
                </span>

              </Grid>


              <Grid item xs="6" sm="3">
                <span className={classes.labeledSpan}>
                  <Typography color="textPrimary" variant="body2" style={{ marginRight: '3px' }} >

                    {t('kind')}
                  </Typography>

                  <Typography color="textSecondary" variant="body2" >

                    {kinds(t)[this.props.item.kind]}
                  </Typography>

                </span>

              </Grid>

              <Grid item xs="6" sm="3">
                <span className={classes.labeledSpan}>
                  <Typography color="textPrimary" variant="body2" style={{ marginRight: '3px' }} >
                    {t('category')}
                </Typography>
                  <Typography color="textSecondary" variant="body2" >

                    {this.props.item.category.label}
                  </Typography>
                </span>
              </Grid>


              <Grid item xs="6" sm="3">
                <span className={classes.labeledSpan}>
                  <Typography color="textPrimary" variant="body2" style={{ marginRight: '3px' }} >
                    {t('assigned_to')}
                </Typography>
                  <Typography color="textSecondary" variant="body2" >

                    {this.props.item.assigned_to.label}
                  </Typography>
                </span>
              </Grid>


              <Grid style={{ marginTop: "5px", marginBottom: '5px' }} item xs="12">
                <Typography color="textPrimary" variant="body2" >
                 {t('description')}
              </Typography>
              </Grid>

              <Grid item xs>
                <Typography color="textSecondary" variant="body2" >

                  {this.props.item.description}
                </Typography>
              </Grid>




            </Grid>

          </Collapse>


        </Paper >
      </div >

    );
  }
}


AssistanceListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withTranslation()(AssistanceListItem)));
