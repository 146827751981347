
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
// import {Redirect} from 'react-router-dom';

const PrivateRoute = ({ component: Component, sessionStatus, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      sessionStatus === true ?
         <Component sessionStatus={sessionStatus} {...props} />
        :  <Redirect to="/login" />
            
          }
  />;

export default PrivateRoute;