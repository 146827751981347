import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import AssistanceListView from './AssistanceListView.js'
import { Flipper } from "react-flip-toolkit";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import KalenisSpinner from '../common/KalenisSpinner.js'
import moment from 'moment';

import _ from 'lodash';

import {

  withRouter,

} from 'react-router-dom'

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  fab:{
    position:'fixed',
    bottom:20,
    right:15

  }
});



class AssistanceMain extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     
      loading: true,
      issue_list:[],
      fullIssueList:[],
      screnIssueList:[],
      selected_order:"",
      flip:true
      
    }
    this.orderBy = this.orderBy.bind(this);
    this.orderMaxMin = this.orderMaxMin.bind(this);
    this.reverseOrder = this.reverseOrder.bind(this)
    this.search = this.search.bind(this);
    this.newAssistanceRequest = this.newAssistanceRequest.bind(this);
    this.getIssuesList = this.getIssuesList.bind(this);
    // this.getIssues = this.getIssues.bind(this);
    // this.display = this.display.bind(this);
    // this.loadIssueList = this.loadIssueList.bind(this);

  }

  componentDidMount(){
    // this.getIssues()
    // this.display("issue1")
    console.log("session in roundup")
    console.log("Monto assistance main")
    console.log(this.props.session)
    this.getIssuesList()
    
    // this.state.fullIssueList.map(function(issue){
    //   var filter = function (key, val) {
    //     // Return false if you want to ignore field
    //     if (key === 'status' || key === 'notifications') {
    //         return false;   // Ignore field
    //     }
    
    //     return true;    // Accept field
    // };

    //   this.searcher.add(issue, filter)



    // }.bind(this))

  
  }

  getIssuesList(search, orderBy){
   
    // ?id=43&description="test"$state=open;pending
    if(this.props.selectedCompany === false){
      return false;
    }
    if(search === undefined){
      search = "?party=".concat(this.props.selectedCompany)
    }
    else{
      search = search.concat("party=").concat(this.props.selectedCompany)
    }

    console.log("Search Args on Main")
    console.log(search)

    


    return fetch(" /api/kalenis_web/support/issue"+search, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      // body: body_req

    })
      .then((response) => {
        
        if(response.status === 401){
          this.props.logout()
          this.props.history.push({pathname:'login'})
          return false
        }
        return response.json();
      }).then((json) => {

        if (!json || json.status === false) {
          alert("Ha ocurrido un error")
          return false
        }

        console.log("response")
        console.log(json)

        this.setState({
          fullIssueList:json,
          screnIssueList:json,
          loading:false,
        }, ()=>{ if(orderBy){this.orderBy(orderBy, orderBy.reverse)}})

      })
  }


  orderMaxMin(key){
    var ordered = this.state.screnIssueList.sort(function(a, b) {
      return b[key] - a[key];
  });

  this.setState({
    screnIssueList:ordered
  })
  }

  orderByDate(key){
    var ordered = this.state.screnIssueList.sort(function(a, b) {
      return moment(b[key]) - moment(a[key]);
  });

  this.setState({
    screnIssueList:ordered
  })
  }

  orderByAlph(key){
    var ordered = this.state.screnIssueList.sort(function(a,b) {
      
      if ( a[key].label > b[key].label ){
        return 1;
      }
      if ( a[key].label < b[key].label ){
        return -1;
      }
      return 0;
  });

  this.setState({
    screnIssueList:ordered,
  })
  }


  orderBy(param, reverse){
    console.log("Ordenando en el origen de datos")
    console.log(param)
    
    switch(param.type){
      case 'date':
        this.orderByDate(param.value)
        break;
      case 'number':
        this.orderMaxMin(param.value)
        break;
      case 'object':
        this.orderByAlph(param.value)
        break;
      default:
        this.orderMaxMin(param.value)
        break;
    }
  //  this.orderMaxMin(key)
    
    this.setState({
      selected_order:param.value,
      flip:param.value
    })

    console.log("Reverse in order by")
    console.log(reverse)
    if(reverse){
      this.reverseOrder()
      return true;
    }
  }

  reverseOrder(){
    console.log("Reversing")
    var reversed = this.state.screnIssueList.reverse()
    this.setState({
      screnIssueList:reversed
    })
    
  }

  search(value, orderBy){
    this.setState({loading:true})
    // var search = "?id="+value
    var search = "?"

    console.log("Search value Received")
      console.log(value)

    Object.keys(value).map(function(field){// eslint-disable-line
      if(value[field].value !== "" && value[field].value !== undefined ){
        // var field_query = field.concat('=').concat(value[field]).concat('&')

        var field_value = value[field].value
        if(_.isArray(field_value)){
          field_value = field_value.join(';')
        }
        search = search.concat(field).concat('=').concat(field_value).concat('&')
      }
     
      
    })
    

    this.getIssuesList(search, orderBy)

  }


  newAssistanceRequest(){
    this.props.history.push('/support/new')
  }


  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
       
        <KalenisSpinner fullScreen={true} size={50} show={this.state.loading}/>
      
       
         
        {/* {this.state.fullIssueList[0]  && */}
        <Flipper flipKey={this.state.screnIssueList.map(function(issue){
                  return issue.id
                }).join('')}>
         <AssistanceListView
          session={this.props.session}
          issues={this.state.screnIssueList}
          orderBy={this.orderBy}
          selected_order={this.state.selected_order}
          flip={this.state.flip}
          reverseOrder={this.reverseOrder}
          search={this.search}
          selectedCompany={this.props.selectedCompany} />
         </Flipper>
        {/* } */}
        <Fab color="primary" aria-label="Add" onClick={this.newAssistanceRequest} className={classes.fab}>
        <AddIcon />
      </Fab>
       
      </React.Fragment>
      

    );
  }
}


AssistanceMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AssistanceMain));
