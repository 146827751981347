import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

import KalenisSpinner from '../common/KalenisSpinner.js'

import Grow from '@material-ui/core/Grow';

import '../App.css';


import {
   
   
    withRouter,

} from 'react-router-dom'


// const AssistanceListItemFlipped = ({ flippedProps }) => (<AssistanceListItem {...flippedProps}/>)
const ReactMarkdown = require('react-markdown/with-html')

const styles = theme => ({
    paper: {
        maxWidth: 5,
        margin: 'auto',
        overflow: 'hidden',
        color:'green'
    },
    mainDoc: {
        textAlign: "left",
        padding: theme.spacing.unit * 4,
        maxWidth: '100%',
        // wordBreak: 'break-all',
        // wordWrap: 'break-word',
        margin: 'auto',
        overflowX: 'hidden',
        fontWeight:'300',
        lineHeight:1.5,
        
        


    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing.unit,
    },
    contentWrapper: {
        margin: '40px 16px',
    },
});


class DocPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            source: "",
            loading: true,
        }
        this.getSource = this.getSource.bind(this)


    }

    componentDidMount() {
        console.log("Mounting doc page")
        console.log(this.props.location.pathname.substring(10))
        this.getSource(this.props.location.pathname.substring(10))
    }

    getSource(module) {

        fetch('/md_sources/' + module + '.md')
            .then(response => response.text())
            .then(result => this.setState({ source: result, loading: false }));

    }


    renderImage(props) {
        return <img {...props} style={{ maxWidth: '100%' }} alt="Kalenis Learning Center" />
    }

    renderTable(props) {
        return <table {...props} className="doc-table" />
    }

    renderTableRow(props) {
        return <tr {...props} className="doc-table-tr" />
    }

    renderTableCell(props) {
        return <td {...props} className="doc-table-td" />
    }

    renderTableHead(props){
        return <thead {...props} className="doc-table-th" />
    }

    renderBlockQuote(props){
        console.log("Render block quote")
        // var title = props.children[0].props.children[0].props.children[0].props.value
        // if(title === "deps"){
        //     props = props.
        // }
        return <Paper {...props} className="dependencies-block" ></Paper>
        // return <div {...props} className="dependencies-block"></div>
    }

    renderHeading(props){

        console.log("Heading Level")
        console.log(props.level)
        switch(props.level){
            case 3: return  <h3  {...props} className="doc-middle-titles"></h3>
            case 2: return  <h2  {...props} className="doc-main-titles"></h2>
        }

        
        // return <h5  {...props} style={{color:'red'}}></h5>
    }


    render() {
        const { classes } = this.props;


        return (
            <Grow in={true} style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 500 } : {})}>
            <div className={classes.mainDoc}>


                <KalenisSpinner fullScreen={true} size={50} show={this.state.loading} />
                
                    <ReactMarkdown source={this.state.source}
                        escapeHtml={false}
                        renderers={{
                            image: this.renderImage,
                            table: this.renderTable,
                            tableRow: this.renderTableRow,
                            tableCell: this.renderTableCell,
                            tableHead:this.renderTableHead,
                            blockquote: this.renderBlockQuote,
                            heading:this.renderHeading

                        }} />
                
            </div>
            </Grow>


        );
    }
}


DocPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(DocPage));
