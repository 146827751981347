import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import kalenisLogo from './isotipo-kalenis-ilust.svg'
import KalenisSpinner from './common/KalenisSpinner.js'
import Grid from '@material-ui/core/Grid';
import { FormHelperText } from '@material-ui/core';

import { Formik} from 'formik';
import * as Yup from 'yup';

import {

  withRouter,

} from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    // padding:10,
    backgroundColor: 'white',
    width: 50,
    height: 50
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  forgotPassword: {
    marginTop: theme.spacing.unit * 3,
    textAlign: 'center',

  },
  forgotPasswordText: {

    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      // color: 'rgb(47,136,222)',
      color: "#203b60",
    },

  },
  button: {
    width: '100%'
  }
});

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirm: "",
      loading: false,
      forgotDialog: false,
      infoDialog: false,
      infoMessage: { title: "", msg: "" },
      invalid_fields: { password: { status: false, msg: "" }, password_confirm: { status: false, msg: "" } },
      fields_schema: Yup.object().shape({
        password: Yup.string()
          .min(8, 'La contraseña debe poseer 8 caracteres como mínimo')
          .max(50, 'La contraseña debe poseer 50 caracteres como máximo')
          .matches(/[a-z]/, 'La contraseña debe poseer al menos una letra')
          .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'La contraseña debe poseer al menos un número')
          .required('Requerido'),
        password_confirm: Yup.string()
          .min(8, 'La contraseña debe poseer 8 caracteres como mínimo')
          .max(50, 'La contraseña debe poseer 50 caracteres como máximo')
          .required('Requerido')
          .oneOf([Yup.ref('password'), null], "Las contraseñas no coinciden")

      }),
    }

    this.setNewPassword = this.setNewPassword.bind(this);
    this.handleTextField = this.handleTextField.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    // this.submitRequest = this.submitRequest.bind(this);
    this.handleInfoDialog = this.handleInfoDialog.bind(this);
    // this.validatePassword = this.validatePassword.bind(this);
    // this.validateForm = this.validateForm.bind(this);
    // this.handleEnter = this.handleEnter.bind(this);
    this.goToLogin = this.goToLogin.bind(this);


    // this.registerUser = this.registerUser.bind(this);

  }

  componentDidMount() {
    var params = new URLSearchParams(this.props.history.location.search);
    // console.log(this.props.history.location.search)
    console.log(params.get('email'))
    console.log(params.get('token'))
    this.setState({
      url: window.location.href,
      // url:"https://testdashboard.kalenislims.com/reset_password"+this.props.history.location.search,
      url_params: params
    })
  }



  handleTextField(event) {

    const name = event.target.name;
    this.setState({
      [name]: event.target.value
    });

  }



  handleInfoDialog(infoMessage) {
    var msg;
    if (infoMessage) {
      msg = infoMessage
    }
    else {
      msg = { title: "", msg: "" }
    }

    this.setState({
      infoDialog: !this.state.infoDialog,
      infoMessage: msg
    })
  }


  goToLogin() {
    this.handleInfoDialog()
    this.props.history.push('/login')
  }

  setNewPassword(values) {
    var body_req = JSON.stringify({
      url: values.url,
      password: values.password

    })

    return fetch("/api/kalenis_web/user/set_password_url", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: body_req

    })
      .then((response) => {

        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }

      }).then((json) => {

        if (json) {
          // this.props.login(json)
          // this.props.history.push('/login')
          // localStorage.setItem("email", this.state.email)
          // localStorage.setItem("password", this.state.password)
          this.handleLoading()
          this.handleInfoDialog({ title: "Operación Exitosa", msg: "Su contraseña ha sido reestablecida con éxito." })

        }
        else {
          console.log("Respnse disinta de 200")
          this.handleLoading()
          this.handleInfoDialog({ title: "Verifique los datos ingresados", msg: "Ha ocurrido un error" })
        }

        console.log("pase")

      })
  }


  handleLoading() {
    this.setState({
      loading: !this.state.loading
    })
  }

 

  render() {
    //bugpoint
    // const { classes, ...other } = this.props;
    const { classes } = this.props;
    return (
      <main className={classes.main}>

        <CssBaseline />


        <Paper className={classes.paper}>
          {/* <Avatar src={kalenisLogo} className={classes.avatar}> */}
          {/* <LockOutlinedIcon /> */}

          <img style={{ height: 50, width: 50 }} src={kalenisLogo} alt="Kalenis"></img>
          {/* </Avatar> */}
          <Typography component="h1" variant="h5">
            Establecer Contraseña
        </Typography>


          <Formik
            initialValues={{
              password: "",
              password_confirm: "",
              // url:window.location.href,
              // url:"https://testdashboard.kalenislims.com/reset_password"+this.props.history.location.search
              url:window.location.href

            }}


            validationSchema={this.state.fields_schema}
            onSubmit={values => {
              // same shape as initial values
              this.setNewPassword(values)
              console.log(values)
            }}
          >
            {({ errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              values }) => (
                <form
                 className={classes.form}
                 onKeyDown={(e)=>{
                  
                  if (e.key === 'Enter') {
                   
                    e.preventDefault()
                    e.stopPropagation()
                    handleSubmit()
                 }}}
                 onSubmit={handleSubmit}
                 noValidate>

                  <Grid container spacing={0}>
                    <KalenisSpinner show={this.state.loading} fullScreen={true} size={50} />
                    <Grid item xs="12">
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel error={errors.password && touched.password ? true:false} htmlFor="password">Nueva Contraseña</InputLabel>
                        <Input  error={errors.password && touched.password ? true:false} id="password" type="password" name="password" value={values.password} onChange={handleChange} autoFocus />
                        <FormHelperText error={errors.password && touched.password ? true:false}>{errors.password && touched.password ? errors.password:false}</FormHelperText>

                      </FormControl>
                    </Grid>
                    <Grid item xs="12">
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel error={errors.password_confirm && touched.password_confirm ? true:false}  htmlFor="password_confirm">Confirmar Contraseña</InputLabel>
                        <Input error={errors.password_confirm && touched.password_confirm ? true:false} name="password_confirm" value={values.password_confirm} type="password" onChange={handleChange} id="password_confirm" />
                        <FormHelperText error={errors.password_confirm && touched.password_confirm ? true:false}>{errors.password_confirm && touched.password_confirm ? errors.password_confirm:false}</FormHelperText>

                      </FormControl>
                    </Grid>



                    <Grid item xs="12">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        // onClick={this.setNewPassword}
                        className={classes.submit}
                      >
                        Establecer Contraseña
          </Button>

                    </Grid>



                  </Grid>

                </form>
              )}
          </Formik>


          <Dialog
            // fullScreen={fullScreen}
            open={this.state.infoDialog}
            onClose={this.handleInfoDialog}
            maxWidth='sm'
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="info-dialog-title">{this.state.infoMessage.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="info-dialog-description">
                {this.state.infoMessage.msg}
              </DialogContentText>

            </DialogContent>
            <DialogActions>
              <Grid justify={'flex-end'} container spacing={8}>
                <Grid item xs={12} sm={4}>
                  <Button onClick={this.goToLogin} className={classes.button} variant="contained" color="primary" autoFocus>
                    Aceptar
                      </Button>
                </Grid>

              </Grid>

            </DialogActions>
          </Dialog>
        </Paper>
      </main>
    );
  }
}


ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ResetPassword));
