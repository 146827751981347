import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import AssistanceFormTalkItem from './AssistanceFormTalkItem.js'
import KalenisSpinner from '../common/KalenisSpinner.js';
import Typography from '@material-ui/core/Typography';

import '../../node_modules/react-dropzone/examples/theme.css'
import AddIcon from '@material-ui/icons/Add';


import DoneIcon from '@material-ui/icons/Done';
import BugReportIcon from '@material-ui/icons/BugReport';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment';
import { withTranslation } from 'react-i18next';


import FileSaver from 'file-saver';
import _ from 'lodash';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {

  withRouter,

} from 'react-router-dom'

// const priority = [
// ('4', '4-Low'),
// ('3', '3-Normal'),
// ('2', '2-High'),
// ('1', '1-Important'),
// ]


const fileThumbs = {
  '.pdf': '/pdf_logo.png',
  '.xls': '/xls_logo.png',
  'xlsx': '/xls_logo.png',
  '.doc': '/doc_logo.png',
  'docx': '/doc_logo.png',
  '.odt': '/doc_logo.png',
  'default': '/file_logo.png'
}

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    overflow: 'hidden',
  },


  textFieldLabel: {
    // color: "red",
    textAlign: "center",
    fontFamily: [
      'Open Sans',
      'sans-serif'

    ].join(','),
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    // // width: 50,

    // color: "#203b60",
    textAlign: "left",
    fontWeight: '500',

    fontFamily: [
      'Open Sans',
      'sans-serif'

    ].join(','),
   
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  alignLeft: {
    textAlign: 'left'
  },
  formDivider: {
    // width:'80%',
    color: '1px solid rgba(0, 0, 0, 0.12)',
    margin: theme.spacing.unit * 2

  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {

      boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.3)',
      // color:'red'
      // -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
      // -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
      // box-shadow: ;
    },

  },
  thumbTitle: {
    // color:'rgba(0, 0, 0, 0.5)',

  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  thumbImg: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  thumbContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '0.8em',

    '&:hover': {
      // color: 'rgb(47,136,222)',

      color: "#203b60",
      textAlign: "left",
      fontWeight: '500',

      fontFamily: [
        'Open Sans',
        'sans-serif'

      ].join(','),
      // -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
      // -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
      // box-shadow: ;
    },

    // padding: theme.spacing.unit * 8
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing.unit * 2
  },
  button: {
    width: '100%',

  },
  buttonOpen: {
    width: '100%',
    color: 'rgb(47,136,223)'
  },
  buttonDone: {
    width: '100%',
    // backgroundColor:'green'
    color: 'green'
  },
  buttonPending: {
    width: '100%',

  },
  selectMenu: {
    width: 200,
    zIndex: 9999,
    backgroundColor: 'red'
  }
});


moment.locale('es')
const state = (t) => [
  { 'value': 'open', 'label': t('open') },
  { 'value': 'pending', 'label': t('feedback') },
  { 'value': 'done', 'label': t('finished') }
]

const priorities = (t) => [
  { 'value': "4", 'label': t('low') },
  { 'value': "3", 'label': t('normal') },
  { 'value': "2", 'label': t('high') },
  { 'value': "1", 'label': t('critical') },

]

const kind =  (t) => [
  { 'value': 'bug', 'label': t('error') },
  { 'value': 'question', 'label': t('question') }
]



// const priority = [
// ('4', '4-Low'),
// ('3', '3-Normal'),
// ('2', '2-High'),
// ('1', '1-Important'),
// ]



class AssistanceForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      menu_open: false,
      anchorEl: null,
      files: [],
      priority: "",
      kind: "",
      assigned: "",
      date: "",
      date_closed: "",
      title: "",
      talkDialog: false,
      newTalkComment: "",
      infoDialog: false,
      infoMessage: { title: "", msg: "" },
      assigned_options: [{}],
      talks: [],
      category: "",
      categoryOptions: [],
      state: "",
      description: "",
      attachments: [],
      missing_company:false,
      initialValues: {
        title: "",
        category: "",
        kind: "",
        assigned_to: "",
        description: "",
        priority: ""
      },
      fields_schema: Yup.object().shape({
        title: Yup.string()
          .max(50, '50 Chars. Max.')
          .required('Required'),
        category: Yup.string()
          .required('Required'),
        kind: Yup.string()
          .required('Required'),
        assigned_to: Yup.string()
          .required('Required'),
        priority: Yup.string()
          .required('Required'),
        description: Yup.string()
          .required('Required'),

      }),

    }
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.onDrop = this.onDrop.bind(this)
    this.updateState = this.updateState.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.updateIssue = this.updateIssue.bind(this);
    this.handleTextField = this.handleTextField.bind(this);
    this.goToList = this.goToList.bind(this);
    this.openTalkDialog = this.openTalkDialog.bind(this);
    this.closeTalkDialog = this.closeTalkDialog.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.handleInfoDialog = this.handleInfoDialog.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.saveNewTalk = this.saveNewTalk.bind(this);
    this.handleNewTalkEnter = this.handleNewTalkEnter.bind(this);

  }

  componentDidMount() {
   
    this.getCategories()

   

    let params = new URLSearchParams(this.props.history.location.search);

    if (params.has('id')) {
      
      // console.log(params.get('id'))  
      this.handleLoading()
      this.getIssueDetail(params.get('id'));
      this.markAsRead(params.get('id'))
    }
    else {

      if(this.props.selectedCompany != 0){
        var selectedCompany = [...this.props.session.user.parties].filter(function(party){return party.id === this.props.selectedCompany}.bind(this))
       
        var assigned = [
          { 'value': selectedCompany[0].id, 'label': selectedCompany[0].name },
          { 'value': 0, 'label': 'Kalenis' }
        ]
        this.setState({
          assigned_options: assigned
        })
      }
      else{
        this.setState({
          missing_company:true
        })
      }
     

       this.setState({ state: 'new' }) 
      }



  }

  markAsRead(id) {
    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)
    return fetch("/api/kalenis_web/support/issue/" + id + "/mark_as_read"+company, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      // body: body_req

    })
      .then((response) => {

        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }

      })
  }



  getCategories(party_id) {
    if(this.props.selectedCompany === false){
      return false
    }
    var party;
    if(party_id){
      party = party_id
    }
    else{
      party = this.props.selectedCompany
    }
    var company = "?party=".concat(party)
    return fetch("/api/kalenis_web/support/category"+company, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      // body: body_req

    })
      .then((response) => {
        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }


      }).then((json) => {

        if (json) {
        
          this.setState({
            categoryOptions: json,

          })
        }
        else {
          this.handleLoading()
          this.handleInfoDialog({ title: this.props.t("error_message"), msg: this.props.t("try_again_later") })
        }


      })
  }


  getIssueDetail(id) {
    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)
    return fetch("/api/kalenis_web/support/issue/" + id + company, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      // body: body_req

    })
      .then((response) => {
        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }


      }).then((json) => {

        if (json) {
          // console.log("response Issue Date")
          // console.log(moment(json.date).format("YYYY-MM-DD"))
          // console.log(json);

          var initialValues = {
            title: json.title,
            assigned_to: json.assigned_to.id,
            description: json.description,

            id: json.id,
            kind: json.kind,
            priority: "2",

            category: json.category.id

          }

          var selectedCompany = [...this.props.session.user.parties].filter(function(party){return party.id === json.party}.bind(this))
          
          var assigned = [
            { 'value': selectedCompany[0].id, 'label': selectedCompany[0].name },
            { 'value': 0, 'label': 'Kalenis' }
          ]

          this.getCategories(json.party)
         

          this.setState({
            initialValues: initialValues,
            attachments: json.attachments,
            loading: false,
            state: json.state.id,
            assigned_options: assigned,
            date: moment.utc(json.date).local().format('YYYY-MM-DD'),
            // date: moment(json.date).format("YYYY-MM-DD") || "",
            closed_date: json.closed_date ? moment.utc(json.closed_date).local().format('YYYY-MM-DD') : "",
            talks: json.talks,

          }, () => this.handleInitialAttachments(json.attachments))

        }
        else {
          this.handleLoading()
          this.handleInfoDialog({ title: this.props.t("check_input"), msg: this.props.t("check_user_pwd") })
        }


      })
  }

  handleInitialAttachments(attachments) {
    // get image attachment for preview
    attachments.map(function (file) {
      var extension = file.name.substring(file.name.length, (file.name.length - 4))
      if (extension === '.png' || extension === '.jpg' || extension === 'jpeg') {
        this.getAttachment(file.id)
      }

    }.bind(this))
  }

  showAttachment(e, file) {
    


    if (file.uri) {
      FileSaver.saveAs(file.uri, file.name);
    }
    else {
      this.getAttachment(file.id, true, file.name)
    }






  }

  getAttachment(id, download, name) {
    // args:id(requerido): Id del attach
    // download(opcional): Descarga del archivo
    // name(opcional): usado para nombrar la descarga directa
    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)

    var attachments = [...this.state.attachments]
    return fetch("/api/kalenis_web/support/issue/" + this.state.initialValues.id + '/attach/' + id + company, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      // body: body_req

    })
      .then((response) => {
        if (response.status !== 200) {

          return false
        }
        else {
          return response.blob();
        }


      }).then((blob) => {

        if (blob) {
       
          var preview = false;
          if (blob.type.startsWith('image')) {
            preview = true
          }
          if (download) {
            FileSaver.saveAs(blob, name);
          }
          var dataUrl = URL.createObjectURL(blob)
          // console.log(dataUrl)
          
          attachments.find((o, i) => {// eslint-disable-line
            if (o.id === id) {
              attachments[i]['uri'] = dataUrl;

              attachments[i]['preview'] = preview;
              // console.log("Updated attachments")
              // console.log(attachments)
              this.setState({ attachments: attachments })
              return true; // stop searching
            }

          });

        }
        else {
          this.handleLoading()
          this.handleInfoDialog({ title: this.props.t("check_input"), msg: this.props.t("check_user_pwd") })
        }


      })
  }



  handleLoading() {
    this.setState({
      loading: !this.state.loading
    })
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleInfoDialog(infoMessage) {
    var msg;
    if (infoMessage) {
      msg = infoMessage
    }
    else {
      msg = { title: "", msg: "", goBack: false }
    }

    this.setState({
      infoDialog: !this.state.infoDialog,
      infoMessage: msg,
    })
  }

  getFileThumb(extension) {
    // console.log("Extension")
    // console.log(extension)

    if (fileThumbs[extension] !== undefined) {
      return fileThumbs[extension]
    }
    else {
      return fileThumbs['default']
    }


  }


  readFile(file) {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      // console.log("name")
      // console.log(file.name)

      let attachments = [...this.state.attachments]

      const dataURL = reader.result
      var preview;
      if (file.type.startsWith('image')) {
        preview = true
      }
      else {
        preview = this.getFileThumb(file.name.substring(file.name.length, (file.name.length - 4)))
      }
      let res = { 'name': file.name, 'uri': dataURL, preview: preview }
      attachments.push(res)
      this.setState({ attachments: attachments })
      console.log(dataURL)
    }

    reader.readAsDataURL(file)
  }

  onDrop = (files) => {
    



    files.forEach(file => this.readFile(file))


  };

  setFiles = (files) => {
    console.log("setting Files")
    console.log(files)
  }

  handleTextField(event) {

    
    const name = event.target.name;
    this.setState({
      [name]: event.target.value
    });

  }


  submitForm(values) {
    // e.preventDefault()
    this.handleLoading()
    console.log("Submmiting form")
    // console.log(e.nativeEvent)

    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)

    var body_req = JSON.stringify({

      title: values.title,
      description: values.description,
      priority: values.priority,
      kind: values.kind,
      assigned_to: parseInt(values.assigned_to),
      attachments: this.state.attachments,
      category: parseInt(values.category)


    })

    return fetch("/api/kalenis_web/support/issue"+company, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      body: body_req

    })
      .then((response) => {

        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }

      }).then((json) => {

        if (json) {
          this.handleLoading()
          this.handleInfoDialog({ title:this.props.t("submit_success"), msg: this.props.t("thank_you_response"), goBack: true })
        }
        else {
          
          this.handleInfoDialog({ title: this.props.t("error_message"), msg: this.props.t("try_again_later"), goBack: true })
        }
        console.log("response")
        console.log(json)

      })
  }


  updateState(state, values) {

    this.setState({
      state: state
    }, () => { this.updateIssue(values) })
  }

  updateIssue(values) {
    this.handleLoading()
    console.log("Update Issue")
    var body_req = JSON.stringify({
      issue_id: parseInt(this.state.initialValues.id),
      title: values.title,
      description: values.description || "",
      priority: values.priority,
      kind: values.kind,
      assigned_to: parseInt(values.assigned_to),
      state: this.state.state || 'open',
      attachments: this.state.attachments.filter((o) => { return o.id === undefined }),
      category: parseInt(values.category)
    })
    console.log("Body Req on PUT")
    console.log(body_req)
    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)
    return fetch("/api/kalenis_web/support/issue/" + this.state.initialValues.id + company, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      body: body_req

    })
      .then((response) => {

        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }

      }).then((json) => {

        if (json) {
          this.handleLoading()
          this.handleInfoDialog({ title:this.props.t("submit_success"), msg: this.props.t("thank_you_response") })
        }
        else {
          this.handleInfoDialog({ title: this.props.t("error_message"), msg: this.props.t("try_again_later") })
        }
        console.log("response")
        console.log(json)

      })

  }
  goToList() {
    if (this.state.infoDialog) {
      this.handleInfoDialog()
    }
    this.props.history.push('/support/list')
  }

  openTalkDialog() {
    this.setState({ talkDialog: true })
  }

  closeTalkDialog() {
    this.setState({ talkDialog: false })
  }

  submitComment(values) {
    this.closeTalkDialog()
    this.handleLoading()
    console.log("Comment")
    console.log(this.state.newTalkComment)
    // var message = this.state.newTalkComment;
    var talks = [...this.state.talks]
  

    var new_talk = {
      date: moment(),
      message: values.comment,
      web_user: this.props.session.user.user_email
    }

    talks.push(new_talk)



    this.saveNewTalk(talks, new_talk)




  }

  saveNewTalk(talks, new_talk) {

    var body_req = JSON.stringify(new_talk)
    if(this.props.selectedCompany === false){
      return false
    }
    var company = "?party=".concat(this.props.selectedCompany)

    return fetch("/api/kalenis_web/support/issue/" + this.state.initialValues.id + "/talk"+company, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.session.token
      },
      mode: 'cors',
      body: body_req

    })
      .then((response) => {

        if (response.status !== 200) {

          return false
        }
        else {
          return response.json();
        }

      }).then((json) => {

        if (json) {

          this.getIssueDetail(this.state.initialValues.id);
          this.setState({ newTalkComment: "", loading: false })

        }
        else {
          this.handleInfoDialog({ title: this.props.t("error_message"), msg: this.props.t("try_again_later") })
        }
        console.log("response")
        console.log(json)

      })
  }


  handleNewTalkEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.submitComment();

    }

  }

  render() {
    const { classes, t } = this.props;

    return (
      <React.Fragment>

        <KalenisSpinner show={this.state.loading} fullScreen={true} size={50} />
        {this.state.missing_company &&
         <KalenisSpinner show={this.state.missing_company} fullScreen={true} size={50} message={t("missing_company_request")} />
        }
       
        <Formik
          initialValues={{ ...this.state.initialValues }}

          enableReinitialize={true}
          validationSchema={this.state.fields_schema}
          // validateOnChange={true}

          onSubmit={values => {
            // same shape as initial values
            // this.setNewPassword(values)
            this.submitForm(values)
            console.log(values)
          }}
        >
          {({ errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            validateOnChange,
            validateForm,
            isValid
          }) => (

              <form onSubmit={handleSubmit} noValidate>
                <Paper className={classes.paper}>

                  <Grid className={classes.alignLeft} container spacing={24}>

                    <Grid item xs={12} >

                      <TextField
                        error={errors.title && touched.title ? true : false}
                        helperText={errors.title && touched.title ? errors.title : false}
                        disabled={this.state.state === 'done' ? true : false}
                        required
                        id="title"
                        name="title"
                        label={t("title")}
                        fullWidth
                        variant="outlined"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}

                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel,
                          },
                        }}
                      />

                    </Grid>

                    <Grid item xs={12} sm={12} >


                      <TextField
                        error={errors.category && touched.category ? true : false}
                        helperText={errors.category && touched.category ? errors.category : false}
                        disabled={this.state.state === 'done' ? true : false}
                        id="category"
                        name="category"
                        label={t("category")}
                        fullWidth
                        select
                        variant="outlined"
                        onChange={handleChange}
                        value={values.category}
                        onBlur={handleBlur}
                        // helperText="Please select your currency"
                        InputProps={{

                          classes: {
                            root: classes.textField,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel,
                          },
                        }}
                        SelectProps={{ 
                          
                          MenuProps: {
                            // className:"select-field-item",
                            classes:{
                             paper:classes.textField
                            },
                            MenuListProps:{
                              
                              classes:{
                                root: classes.textField
                              },
                              
                            }
                          //  PaperProps: {root:{backgroundColor:'red'}} } 
                          }
                          
                          }}

                      >

                        {this.state.categoryOptions.map(option => (
                          <MenuItem className="select-field-item" key={"category_" + option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}

                      </TextField>


                    </Grid>

                    <Grid item xs={12} sm={4} >


                      <TextField
                        required
                        error={errors.kind && touched.kind ? true : false}
                        helperText={errors.kind && touched.kind ? errors.kind : false}
                        disabled={this.state.state === 'done' ? true : false}
                        id="kind"
                        name="kind"
                        label={t("request_type")}
                        fullWidth
                        select
                        variant="outlined"
                        onChange={handleChange}
                        value={values.kind}
                        InputProps={{
                          required: true,
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel,
                          },
                        }}

                      >

                        {kind(t).map(option => (
                          <MenuItem className="select-field-item" key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}

                      </TextField>


                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <TextField
                        required
                        error={errors.assigned_to && touched.assigned_to ? true : false}
                        helperText={errors.assigned_to && touched.assigned_to ? errors.assigned_to : false}
                        disabled={this.state.state === 'done' ? true : false}
                        id="assigned_to"
                        name="assigned_to"
                        label={t("assigned_to")}
                        fullWidth
                        select
                        variant="outlined"
                        onChange={handleChange}
                        value={values.assigned_to}
                        InputProps={{
                          required: true,
                          classes: {
                            root: classes.textField,

                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel,
                          },
                        }}

                      >

                        {this.state.assigned_options.map(option => (
                          <MenuItem className="select-field-item" key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}

                      </TextField>


                    </Grid>


                    <Grid item xs={12} sm={4} >



                      <TextField
                        error={errors.priority && touched.priority ? true : false}
                        helperText={errors.priority && touched.priority ? errors.priority : false}
                        disabled={this.state.state === 'done' ? true : false}
                        required
                        id="priority"
                        name="priority"
                        label={t("priority")}
                        fullWidth
                        select
                        variant="outlined"
                        onChange={handleChange}
                        value={values.priority}
                        // SelectProps={{

                        //   MenuProps: {
                        //     className: classes.selectMenu
                        //   }
                        // }}
                        InputProps={{

                          classes: {
                            root: classes.textField,

                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel,
                          },
                        }}

                      >

                        {priorities(t).map(option => (

                          // <option value={option.value}>{option.label}</option>
                          <MenuItem className="select-field-item" key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}

                      </TextField>


                    </Grid>


                    <Grid item xs={12}>
                      <TextField
                        error={errors.description && touched.description ? true : false}
                        helperText={errors.description && touched.description ? errors.description : false}
                        disabled={this.state.state === 'done' ? true : false}
                        id="description"
                        required
                        onBlur={handleBlur}
                        name="description"
                        label={t("description")}
                        fullWidth
                        multiline
                        rows={10}
                        variant="outlined"

                        value={values.description}
                        onChange={handleChange}
                        // autoComplete="billing address-line2"
                        InputProps={{
                          classes: {

                            root: classes.textField,

                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>



                    </Grid>

                  </Grid>



                </Paper>





                <Paper className={classes.paper}>
                  <Grid className={classes.alignLeft} container spacing={24}>
                    <Grid item xs={12}>

                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps, active = this.state.state !== 'done' }) => (
                          <section className="container">
                            {
                              active === true ?
                                <div {...getRootProps({ className: 'dropzone' })}>

                                  <input {...getInputProps()} />


                                  <p>{t("drop_files_here")}</p>



                                </div> : <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                                  <Toolbar>
                                    <Grid container spacing={16} alignItems="center">
                                      <Grid item>
                                        {/* <SearchIcon className={classes.block} color="inherit" /> */}
                                        <Typography color="textSecondary" variant="default" >


                                          {t("attachments")}
                                          </Typography>
                                      </Grid>
                                      </Grid>
                                      </Toolbar>
                                      </AppBar>



                                      }
          
                            <aside className={classes.aside}>
                                        <Grid container spacing={24}>


                                          {this.state.attachments.map(file => (

                                            // <div>{file.name}</div>
                                            <Grid onClick={function (e) { this.showAttachment(e, file) }.bind(this)} key={file.name} item xs={6} md={2} sm={'auto'} className={classes.thumbContainer}>
                                              <div className={classes.thumb}>
                                                <div className={classes.thumbInner}>

                                                  <img
                                                    src={file.preview === true ? file.uri : this.getFileThumb(file.name.substring(file.name.length, (file.name.length - 4)))}
                                                    alt="-"
                                                    className={classes.thumbImg}
                                                  />

                                                </div>

                                              </div>
                                              <span>{file.name.substring(0, 10) + "[...]" + (file.name.substring(file.name.length, (file.name.length - 4)))}</span>
                                            </Grid>
                                          ))}

                                        </Grid>

                                      </aside>
                          </section>
                                    )}
                      </Dropzone>

                    </Grid>
                  </Grid>
                </Paper>



                          <Paper className={classes.paper}>

                            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                              <Toolbar>
                                <Grid container spacing={16} alignItems="center">
                                  <Grid item>
                                    {/* <SearchIcon className={classes.block} color="inherit" /> */}
                                    <Typography color="textSecondary" variant="default" >


                                      {t("conversation")}
                    </Typography>
                                  </Grid>
                                  <Grid item xs>


                                  </Grid>
                                  <Grid item>
                                    <Tooltip title={t("add_new_comment")}>
                                      {/* disabled={this.state.state ==='done' ? true : false} */}
                                      <IconButton onClick={this.openTalkDialog} color="primary" className={classes.block} disabled={this.state.state === 'done' ? true : this.state.initialValues.id ? false : true}>
                                        <AddIcon />
                                      </IconButton>
                                      {/* <Button onClick={this.openTalkDialog} variant="contained" disabled={this.state.id ? false : true} color="primary" className={classes.addUser}>
                        Añadir
              </Button> */}

                                    </Tooltip>

                                    {/* <Tooltip title="Reload">
                      <IconButton>
                        <RefreshIcon className={classes.block} color="inherit" />
                      </IconButton>
                    </Tooltip> */}
                                  </Grid>
                                </Grid>
                              </Toolbar>
                            </AppBar>

                           

                            {this.state.talks.map((talk) => (

                              
                              <AssistanceFormTalkItem item={{ "author": talk.web_user, 'date': moment.utc(talk.date).local().format(t('date_time_format')), 'msg': talk.message, customer_unread: talk.customer_unread}} />
                            ))}


                          </Paper>

                          <Paper className={classes.paper}>
                            <Grid container alignItems='center' spacing={24}>
                              <Grid item xs={12} sm={4} >
                                <TextField

                                  disabled
                                  id="date"
                                  name="date"
                                  fullWidth
                                  type="date"

                                  label={t("creation_date")}
                                  variant="outlined"

                                  // autoComplete="fname"
                                  value={this.state.date}
                                  onChange={this.handleTextField}
                                  InputProps={{

                                    classes: {
                                      root: classes.textField,

                                    },
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                      root: classes.textFieldLabel,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} >
                                <TextField
                                  required
                                  disabled
                                  id="date_closed"
                                  name="date_closed"
                                  fullWidth
                                  type="date"

                                  label={t("closed_date")}
                                  variant="outlined"

                                  // autoComplete="fname"
                                  value={this.state.closed_date}
                                  onChange={this.handleTextField}
                                  InputProps={{
                                    required: true,
                                    classes: {
                                      root: classes.textField,

                                    },
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                      root: classes.textFieldLabel,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} >
                                <TextField
                                  required
                                  disabled
                                  id="issue_state"
                                  name="issue_state"
                                  label={t("status")}
                                  fullWidth
                                  select
                                  variant="outlined"
                                  onChange={this.handleTextField}
                                  value={this.state.state}
                                  InputProps={{

                                    classes: {
                                      root: classes.textField,

                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.textFieldLabel,
                                    },
                                  }}

                                >

                                  {state(t).map(option => (
                                    <MenuItem className="select-field-item" key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}

                                </TextField>


                              </Grid>
                            </Grid>
                          </Paper>


                          <Paper className={classes.paper}>

                            {/* ('open', 'pending'),
              ('open', 'done'),
              ('pending', 'open'),
              ('pending', 'done'),
              ('done', 'pending') */}

                            <Grid justify={'space-between'} container spacing={8}>

                              <Grid item xs={12} sm={4}>
                                <Button onClick={this.goToList} variant="contained" className={classes.button}>


                                  {t("back_to_list")}
                  </Button>
                              </Grid>


                              {this.state.state === "new" &&
                                <Grid item xs={12} sm={4}>
                                  <Button type="submit" variant="contained" color="primary" className={classes.button}>{t("submit")}</Button>
                                </Grid>
                              }

                              {(this.state.state === "open" || this.state.state === "pending") &&
                                <React.Fragment>
                                  <Grid container sm={5} spacing={8}>
                                    <Grid item xs={12} sm={12}>

                                      {/* <Button id="update_issue" type="button" onClick={this.updateIssue} variant="contained" color="primary" className={classes.button}> */}
                                      <Button id="update_issue" type="button" variant="contained" color="primary" onClick={() => validateForm().then((errors) => { if (_.isEmpty(errors)) { this.updateIssue(values) } else { console.log(errors) } })} className={classes.button}>
                                        {/* <Button id="update_issue" type="button"  variant="contained" color="primary" onClick={()=>isValid===true ? console.log("Valido"):console.log("Invalido")} className={classes.button}> */}
                                        {t("update")}
                        {/* <SaveIcon className={classes.block} color="inherit" /> */}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                      {this.state.state === "open" &&
                                        // <Button type="button" variant="contained" onClick={(e) => { this.updateState(e, "pending") }} className={classes.buttonPending}>
                                        <Button type="button" variant="contained" onClick={() => validateForm().then((errors) => { if (_.isEmpty(errors)) { this.updateState("pending", values) } else { console.log(errors) } })} className={classes.buttonPending}>
                                          {t("feedback")}
                   <BugReportIcon className={classes.block} color="inherit" />

                                        </Button>
                                      }
                                      {this.state.state === "pending" &&

                                        // <Button type="button" variant="contained" onClick={(e) => { this.updateState(e, "open") }} className={classes.buttonOpen}>Abierta</Button>
                                        <Button type="button" variant="contained" onClick={() => validateForm().then((errors) => { if (_.isEmpty(errors)) { this.updateState("open", values) } else { console.log(errors) } })} className={classes.buttonOpen}>
                                          {t('open')}
          
                              </Button>

                                      }

                                    </Grid>
                                    <Grid item xs={12} sm={6}>


                                      <Button type="button" variant="contained" onClick={() => validateForm().then((errors) => { if (_.isEmpty(errors)) { this.updateState("done", values) } else { console.log(errors) } })} className={classes.buttonDone}>
                                        {t('finished')}
                        <DoneIcon className={classes.block} color="inherit" />
                                      </Button>

                                    </Grid>
                                  </Grid>
                                </React.Fragment>

                              }

                            </Grid>

                          </Paper>


              </form>
          
                      )}
        </Formik>
                      <Dialog
                        open={this.state.infoDialog}
                        onClose={this.handleInfoDialog}
                        maxWidth='sm'
                        fullWidth={true}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle id="info-dialog-title">{this.state.infoMessage.title}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="info-dialog-description">
                            {this.state.infoMessage.msg}
                          </DialogContentText>

                        </DialogContent>
                        <DialogActions>
                          <Grid justify={'flex-end'} container spacing={8}>
                            <Grid item xs={12} sm={4}>
                              <Button onClick={this.state.infoMessage.goBack ? this.goToList : this.handleInfoDialog} className={classes.button} variant="contained" color="primary" autoFocus>
                                Aceptar
                      </Button>
                            </Grid>

                          </Grid>

                        </DialogActions>
                      </Dialog>

                      <Dialog
                        // fullScreen={fullScreen}
                        open={this.state.talkDialog}
                        onClose={this.closeTalkDialog}
                        maxWidth='md'
                        fullWidth={true}
                        
                        aria-labelledby="responsive-dialog-title"
                      >
                        <Formik
                          initialValues={{
                            comment:""
                          }}


                            validationSchema={Yup.object().shape({
                              comment: Yup.string()
                               
                                .required(t('required')),
                             
                      
                            })}
                            onSubmit={values => {
                              // same shape as initial values
                              // this.setNewPassword(values)
                              this.submitComment(values)
                             
                            }}
                          >
                            {({ errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                       
                        <DialogContent>
                         

                          
                                  <Grid container spacing={2}>
                                    <Grid item xs="12">
                                      <TextField
                                        id="comment"
                                        error={errors.comment && touched.comment ? true : false}
                                        helperText={errors.comment && touched.comment ? errors.comment : false}
                                        required
                                        name="comment"
                                        label={t("add_new_comment")}
                                        fullWidth
                                        multiline
                                        rows={10}
                                        variant="outlined"
                                        onChange={handleChange}
                                        // onChange={this.handleTextField}
                                        // autoComplete="billing address-line2"
                                        InputProps={{
                                          classes: {
                                            // required: true,
                                            root: classes.textField,
                                            // input: { textAlign: 'center' }
                                          },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.textFieldLabel,
                                          },
                                        }}
                                      />
                                    </Grid>

                                  </Grid>
                       

                        </DialogContent>
                        <DialogActions>
                          <Grid justify={'flex-end'} container spacing={8}>

                            <Grid item xs={12} sm={4}>
                              <Button onClick={this.closeTalkDialog} variant="contained" className={classes.button}>
                                {t('cancel')}
                       </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              {/* <Button onClick={this.submitComment} variant="contained" color="primary" className={classes.button} autoFocus> */}
                              <Button type="submit" variant="contained" color="primary" className={classes.button} autoFocus>
                                {t("add")}
                      </Button>
                            </Grid>

                          </Grid>

                        </DialogActions>
                                   </form>


                                   )}
                              </Formik>
                      </Dialog>


      </React.Fragment>

                    );
                  }
                }
                
                
AssistanceForm.propTypes = {

                      classes: PropTypes.object.isRequired,
                  };
                  
                  export default withStyles(styles)(withRouter(withTranslation()(AssistanceForm)));
