import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Header from './Header';
import MyUsers from './MyUsers.js'
import MyApps from './MyApps.js';
import Login from './Login.js';
import AssistanceMain from './assistance/AssistanceMain.js'
import AssistanceForm from './assistance/AssistanceForm.js'
import PrivateRoute from './PrivateRoute.js'
import DocPage from './klc/DocPage.js'
import ResetPassword from './ResetPassword.js';
import { withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Route,
  
  Redirect,

} from 'react-router-dom'


let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing.unit,
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing.unit,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    
  },
  nullDiv:{
    display:'none'
  },
  mainContent: {
    flex: 1,
    // padding: '48px 36px 0',
    background: '#eaeff1',
  },
};






const session = localStorage.getItem("session")
const ses = JSON.parse(session)


// const klcModules = []

// const klcModules = [
//   {sidemenu:'General',  actions:[
//     {path:"/learning/conf_base", sidemenu:"Uso de la interfaz 1"},
//     {path:"/learning/conf_Ingreso", sidemenu:"Uso de la interfaz 2"},
//     {path:"/learning/conf_laboratorio", sidemenu:"Uso de la interfaz 3"},
//     {path:"/learning/conf_tablas_base", sidemenu:"Uso de la interfaz 4"},

    
//   ]},

// ]


const klcModules = (t) => [

  {sidemenu:t('general'),  actions:[
    {path:t("klc_general_toolbar_path"), sidemenu:t("toolbar")},
    {path:t("klc_general_menues_path"), sidemenu:t("menues")},
    {path:t("klc_general_fields_path"), sidemenu:t("fields")},
    {path:t("klc_general_searcher_path"), sidemenu:t("searcher")},

    
  ]},

  {sidemenu:t('configuration'),  actions:[
    {path:t("/learning/conf_base"), sidemenu:"Base"},
    {path:t("/learning/conf_Ingreso"), sidemenu:t("sample_management")},
    {path:t("/learning/conf_laboratorio"), sidemenu:t("laboratory")},
    {path:t("/learning/conf_tablas_base"), sidemenu:t("base_tables")},
    {path:t("/learning/conf_productos"), sidemenu:t("products")},
    {path:t("/learning/conf_secuencias"), sidemenu:t("sequences")},
    {path:t("/learning/conf_entidades"), sidemenu:t("party")},
    
  ]},

  {sidemenu:t('laboratory'),  actions:[
    {path:t("/learning/ingreso_muestras"), sidemenu:t("sample_management")},
    {path:t("/learning/planificacion"), sidemenu:t("planification")},
    {path:t("/learning/cuaderno_laboratorio"), sidemenu:t("eln")},
    {path:t("/learning/carga_resultados"), sidemenu:t("results")},
    {path:t("/learning/generacion_resultados"), sidemenu:t("report_generation")},
    {path:t("/learning/revision_resultados"), sidemenu:t("results_check")},
    {path:t("/learning/emision_informes"), sidemenu:t("results_report")},
    
    
  ]},

  {sidemenu:'ERP', actions:[
    {path:t("/learning/contabilidad"), sidemenu:t("accounting")},
    {path:t("/learning/pagos"), sidemenu:t("payments")},
    {path:t("/learning/cobranza"), sidemenu:t("collects")},
    {path:t("/learning/compras"), sidemenu:t("purchases")},
    {path:t("/learning/ventas"), sidemenu:t("sales")},
    {path:t("/learning/inventario"), sidemenu:t("stock")}
  ]}
  
  

 
]



class Paperbase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      session: ses || {status:false},
      section_title:"",
      selectedCompany:false

    }

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setTitle = this.setTitle.bind(this)
    this.setInitialTitle = this.setInitialTitle.bind(this);
    this.setInitialCompany = this.setInitialCompany.bind(this)
    this.setCompany = this.setCompany.bind(this);
    // this.getToken = this.getToken.bind(this);
  }


  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  setTitle(title){
    console.log("Setting Title")
    console.log(title)
    
    this.setState({
      section_title:title
    })
  }

  setInitialCompany(){
    var initialCompany;

    if(this.state.session.user){
      if(this.state.session.user.parties.length>1){
        initialCompany = 0
      }
      else{
        initialCompany = this.state.session.user.parties[0].id
      }
  
  
      this.setState({
        selectedCompany:initialCompany
      })

    }

    
  }

  setCompany(id){
    this.setState({
      selectedCompany:id
    })
  }

  componentDidMount() {
    console.log("PAPERBASE PROPS")
    console.log(this.props)
    this.setInitialCompany()
  }

 logout(){
   this.setState({
     session:{status:false}
   })
   localStorage.removeItem("session")
 }

  login(session) {
    this.setState({
      session:session
    })

    localStorage.setItem("session", JSON.stringify(session))
    

  }

  setInitialTitle(path){
    this.setState({
      initialTitle:path
    })
  }


  render() {
    const { classes, t } = this.props;
    
    const publicRoutes = [
      {

        path: '/login',
        exact: true,
        title: "Login",
        sidebar: () => <div>Users!</div>,
        main: () => <Login login={this.login} />
      },

      {

        path: '/reset_password',
        exact: true,
        title: "Establecer Contraseña",
        sidebar: () => <div>Establecer Contraseña</div>,
        main: () => <ResetPassword login={this.login} />
      },


    ]

    const routes = [
      {

        path: '/users',
        exact: true,
        title: "Usuarios",
        sidebar: () => <div>Users!</div>,
        main: () => <MyUsers />
      },
      {

        path: '/apps',
        title: "Aplicaciones",
        exact: true,
        sidebar: () => <div>Apps</div>,
        main: () => <MyApps />
      },
      {

        path: '/support/list',
        title: t("support_center"),
        exact: true,
        sidebar: () => <div>Apps</div>,
        main: () => <AssistanceMain session={this.state.session} selectedCompany={this.state.selectedCompany} logout={this.logout} />
      },
      {

        path: '/support/detail',
        title: "Editar Solicitud",
        exact: true,
        sidebar: () => <div>Support Detail</div>,
        main: () => <AssistanceForm session={this.state.session} selectedCompany={this.state.selectedCompany} />
      },

      {

        path: '/support/new',
        title: t("new_assistance_request"),
        exact: true,
        sidebar: () => <div>Support New</div>,
        main: () => <AssistanceForm session={this.state.session} selectedCompany={this.state.selectedCompany} />
      },

      {

        path: '/invoices',
        title: "Facturas",
        exact: true,
        sidebar: () => <div>Apps</div>,
        main: () => <MyApps />
      },
      {

        path: '/payments',
        title: "Métodos de Pago",
        exact: true,
        sidebar: () => <div>Apps</div>,
        main: () => <MyApps />
      },
      

    ]


    if(this.state.session.access_rights && this.state.session.access_rights.doc === true){
      klcModules(t).map(function(module){// eslint-disable-line
        module.actions.map(function(action){// eslint-disable-line
         routes.push({
   
           path: action.path,
           title: "Kalenis Learning Center",
           exact: true,
           sidebar: () => <div>action.sidemenu</div>,
           main: () => <DocPage />
         })
        })
       })
    }



    return (
    
      <MuiThemeProvider theme={theme}>
        <Router>
         

          <div>


            <Route exact path="/" render={() => (
              this.state.session.status === true ? (
                <Redirect to="/support/list" />
              ) : (
                  <Redirect to="/login" />
                )
            )} />




            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.main}
              // sessionStatus={this.state.session.status}
              />
            ))}
          </div>

          
           

              <div className={(this.state.session.status === true ? classes.root : classes.nullDiv)}>
                <CssBaseline />

                {this.state.session.status === true ? (
                <nav className={classes.drawer}>
                  <Hidden smUp implementation="css">
                    <Navigator
                      klc={this.state.session.access_rights.doc === true ? klcModules(t):[]}
                      PaperProps={{ style: { width: drawerWidth } }}
                      variant="temporary"
                      open={this.state.mobileOpen}
                      onClose={this.handleDrawerToggle}
                      setTitle={this.setTitle}
                      setInitialTitle={this.setInitialTitle}
                      access_rights={this.state.session.access_rights}
                      

                    />
                  </Hidden>
                  <Hidden xsDown implementation="css">
                    <Navigator PaperProps={{ style: { width: drawerWidth } }} access_rights={this.state.session.access_rights}   klc={this.state.session.access_rights.doc === true ? klcModules(t):[]}  />
                  </Hidden>
                </nav>
                ):null}

                <div className={(this.state.session.status === true ? classes.appContent : classes.nullDiv)}>
                {this.state.session.status === true ? (
                  <Header routes={routes} 
                      onDrawerToggle={this.handleDrawerToggle} 
                      logout={this.logout} 
                      selectedCompany={this.state.selectedCompany}
                      session={this.state.session}
                      setCompany={this.setCompany} />
                ):null}

                  <main className={classes.mainContent}>
                  
                    {routes.map((route, index) => (


                      <PrivateRoute
                        key={index}
                        path={route.path}
                        component={route.main}

                        sessionStatus={this.state.session.status}
                      />
                     


                    ))}
                  </main>






                </div>
              </div>
            


          



              
        </Router>
       
      </MuiThemeProvider>
      
    );
  }
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(Paperbase));
