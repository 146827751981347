import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { fade } from '@material-ui/core/styles/colorManipulator';
import { withTranslation } from 'react-i18next';




import {

  withRouter,

} from 'react-router-dom'





const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    marginBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit
    // overflow: 'hidden',
  },
  altPaper: {
    maxWidth: 936,
    margin: 'auto',
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    overflow: 'hidden',
    backgroundColor: 'rgb(249,249,249)'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  issueTitle: {
    // color: "red"

    // backgroundColor: 'red',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',


  },
  issueHeader: {

    // padding: theme.spacing.unit,
    // display: 'flex',
    // flexDirection: 'row',
    // add
    // padding:'0px'
    // backgroundColor:'blue'
  },
  issueHeaderLeft: {
    alignSelf: 'flex-start',
    flexGrow: 1
  },
  issueHeaderRight: {
    alignSelf: 'flex-end',
  },
  grow: {
    flexGrow: 1,
  },
  dateHeader: {
    marginRight: theme.spacing.unit * 3,
    color: "rgba(0, 0, 0, 0.54)",
    whiteSpace: 'nowrap',
    flexGrow: 1

  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  issueBody: {
    padding: theme.spacing.unit,

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  badgeMargin: {
    margin: theme.spacing.unit,
  },
  baseToolbar: {
    padding: '0px'
  },
  labeledSpan: { flexDirection: 'row', display: 'flex' },
  newTalkChip: {
    height: '15px'
  }

});





class AssistanceFormTalkItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      menu_open: false,
      anchorEl: null,

    }
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.openDetail = this.openDetail.bind(this)
  }

  componentDidMount() {
    console.log("LIST ITEM PROPS")
    console.log(this.props)


    // console.log("list view")
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  openDetail() {
    this.props.history.push({
      pathname: 'detail',
      search: '?id=' + this.props.item.id,
      // state: { some: 'state' }
    })
  }



  render() {
    //bugpoint
    // const { classes, ...other } = this.props;
    const { classes, t } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.altPaper}>
          <Grid className={classes.alignLeft} container spacing={0}>

            {this.props.item.customer_unread === true ? 
              <Grid item xs="12" sm="12" justify="flex-start">

                <Chip
                  // icon={<FaceIcon />}
                  label={t("unread")}
                  size="small"
                  // clickable
                  classes={{
                    root: classes.newTalkChip
                  }}

                  color="primary"

                />
              </Grid>:""
          }


            <Grid item xs="12" sm="12">
              <Typography color="textPrimary" variant="body2" >
                {this.props.item.author} [ {this.props.item.date} ]:
              </Typography>

            </Grid>

            {/* <Grid item xs="2" sm="1" justify="flex-end" style={{textAlign:'right'}}>
           
              <Chip
                // icon={<FaceIcon />}
                label="No Leído"
                size="small"
                // clickable
                classes={{
                  root:classes.newTalkChip
                }}

                color="primary"
               
              />
              </Grid> */}


            <Grid item xs="12">
              <Typography style={{whiteSpace:'pre-wrap'}} color="textSecondary" variant="subtitle2" >
              {/* <span > */}
                {this.props.item.msg}
                {/* </span> */}
              </Typography>
            </Grid>
          </Grid>


        </Paper>
      </React.Fragment>

    );
  }
}


AssistanceFormTalkItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withTranslation()(AssistanceFormTalkItem)));
