import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import QuestionAnswerRounded from '@material-ui/icons/QuestionAnswerRounded'
import Collapse from '@material-ui/core/Collapse';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import { withTranslation } from 'react-i18next';

import {
  // BrowserRouter as Router,
  // Route,
  // Link,
  NavLink,
  // Redirect,
  withRouter,

} from 'react-router-dom'



const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      color: '#4fc3f7',
    },
  },

  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    '&$textDense': {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing.unit * 2,
  },
});


class Navigator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedMenus: {'start':true}
    }
    this.toogleMenuExpand = this.toogleMenuExpand.bind(this);
  }

  componentDidMount() {
    console.log("PROPS DEL NAVBAR")
    console.log(this.props)

    // this.props.setInitialTitle(this.props.location.pathname)
  }


  setTitle(childId) {
    this.props.setTitle(childId)
  }


  toogleMenuExpand(e, key) {
    console.log("Expanding Menu")
    console.log(key)
    var expanded = { ...this.state.expandedMenus }

    expanded[key] = !this.state.expandedMenus[key]
    this.setState({
      expandedMenus: expanded
    })
  }

  render() {
    const { classes, t, ...other } = this.props;

    const categories = [
      {
        id: t('requests'),
        children: [
         
          { id: t('new_assistance_request'), icon: <AddIcon />, link: "/support/new" },
          { id: t('support_center'), icon: <QuestionAnswerRounded />, link: "/support/list" },
         
          // { id: 'Gestionar Usuarios', icon: <PeopleIcon />, active: true, link: "/users" },
          // { id: 'Gestionar Aplicaciones', icon: <DnsRoundedIcon />, link: "/apps" },

        ],
      },
      // {
      //   id: 'Mi cuenta',
      //   children: [
      //     { id: 'Facturación', icon: <PublicIcon />, link: "/invoices" },
      //     { id: 'Medios de Pago', icon: <TimerIcon />, link: "/payments" },

      //   ],
      // },
      // {
      //   id: 'Kalenis Learning Center',
      //   children: this.props.klc.map(function (module) {
      //     return { id: module.sidemenu, icon: <SettingsIcon />, link: module.path }
      //   })

      // },
    ];

    return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding>
          <ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
            Kalenis
        </ListItem>
          <ListItem onClick={(e) => { this.toogleMenuExpand(e, 'start') }} className={classNames(classes.item, classes.itemCategory)}>
            <ListItemIcon>
            <QuestionAnswerRounded />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {t('support_center')}
          </ListItemText>
          </ListItem>
          <Collapse style={{marginBottom:'10px'}} in={this.state.expandedMenus['start']} timeout="auto" unmountOnExit>
            {categories.map(({ id, children }) => (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {id}
                  </ListItemText>
                </ListItem>
                {children.map(({ id: childId, icon, active, link }) => (

                  <NavLink key={childId}  style={{ textDecoration: 'none' }} activeClassName={classes.itemActiveItem} to={link}>
                    <ListItem
                      button
                      dense
                      key={childId}
                      className={classNames(
                        classes.item,
                        classes.itemActionable,
                        this.props.location.pathname === link && classes.itemActiveItem,
                      )}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                          textDense: classes.textDense,
                        }}
                      >
                        {childId}
                      </ListItemText>
                    </ListItem>
                  </NavLink>


                ))}



              </React.Fragment>
            ))}
          </Collapse>

          {/* <Divider className={classes.divider} /> */}

          {this.props.access_rights.doc===true &&
          <React.Fragment>
          <ListItem onClick={(e) => { this.toogleMenuExpand(e, 'klc') }} className={classNames(classes.item, classes.itemCategory)}>
          <ListItemIcon>
           
         <QuestionAnswerRounded/>
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Kalenis Learning Center
        </ListItemText>
        </ListItem>


        <Collapse in={this.state.expandedMenus['klc']} timeout="auto" unmountOnExit>
          {this.props.klc.map((category) => (
            <React.Fragment key={category.sidemenu}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {category.sidemenu}
                </ListItemText>
              </ListItem>
              {category.actions.map((child) => (

                <NavLink key={child.sidemenu}  style={{ textDecoration: 'none' }} activeClassName={classes.itemActiveItem} to={child.path}>
                  <ListItem
                    button
                    dense
                    key={child.sidemenu}
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                      this.props.location.pathname === child.path && classes.itemActiveItem,
                    )}
                  >
                    <ListItemIcon> <SettingsIcon /></ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                        textDense: classes.textDense,
                      }}
                    >
                      {child.sidemenu}
                    </ListItemText>
                  </ListItem>
                </NavLink>



              ))}
            </React.Fragment>
          ))}

        </Collapse>
        </React.Fragment>
          }
          
        </List>
      </Drawer>
    );
  }
}


Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withTranslation()(Navigator)));